import React from 'react';
import TeamList from './Team/TeamList';

const Team = () => {

    return (
        <div>
            <main className="p-4 min-h-screen"> 
                <TeamList />
            </main>
        </div>
    );
};

export default Team;
