import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';

const SubscribeModal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-75 transition-opacity duration-300">
      <div className="relative bg-white p-8 rounded-lg shadow-lg w-11/12 max-w-md transform transition-transform duration-300">
        <button onClick={onClose} className="absolute top-4 right-4 text-gray-700 hover:text-gray-900 text-2xl font-bold">&times;</button>
        <div className="text-center">
          <FaCheckCircle className="text-green-500 w-16 h-16 mx-auto mb-4 animate-bounce" />
          {children}
        </div>
      </div>
    </div>
  );
};

export default SubscribeModal;
