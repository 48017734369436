import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getInvoiceType } from '../../../redux/actions/invoiceAction';
import InputBox from '../../../Components/InputBox';
import SelectorDropdown from '../../../Components/SelectorDropdown';
import TextArea from '../../../Components/TextArea';
import FileSelector from '../../../Components/FileSelector';
import { LuMinusCircle, LuPlusCircle } from "react-icons/lu";
import Card from '../../../Components/Card';
import Container from '../../../Components/Container';
import ContentHeader from '../../../Components/ContentHeader';
import ModalLoader from '../../../Components/ModalLoader';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const InvoiceUpload = ({ invoiceData, setInvoiceData, errors, setErrors, handleSubmit }) => {
    const dispatch = useDispatch();
    const [invoiceType, setInvoiceType] = useState([]);
    const [filePreview, setFilePreview] = useState(null);
    const [fields, setFields] = useState([]);
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        dispatch(getInvoiceType()).then((res) => {
            setInvoiceType(res);
        });
    }, [dispatch]);

    console.log('invoiceType', invoiceType);
    

    useEffect(() => {
        const selectedType = invoiceType.find(type => `${type.id}` === invoiceData.selectInvoiceType);
        
        if (selectedType) {
            const description = JSON.parse(selectedType.description);
            if (Array.isArray(description)) {
                setInvoiceData({ ...invoiceData, invoiceFields: description });
            } else {
                console.warn("Description is not an array:", description);
                setInvoiceData({ ...invoiceData, invoiceFields: [] });
            }
        } else {
            setInvoiceData({ ...invoiceData, invoiceFields: [] });
        }
    }, [invoiceData.selectInvoiceType, invoiceType]);

    const simulateProgress = () => {
        const increment = () => {
            setProgress((prevProgress) => {
                const nextProgress = prevProgress + 1;
                return nextProgress >= 95 ? 100 : nextProgress;
            });
        };
        const interval = setInterval(increment, 150);
        return () => clearInterval(interval);
    };

    const handleFieldChange = (index, event) => {
        const { name, value } = event.target;

        if (value.length > 200) {
            // Optionally display an error message
            console.log("Input exceeds the maximum character limit of 200.");
            return;
        }

        const newFields = invoiceData.invoiceFields && invoiceData.invoiceFields.map((field, idx) => {
            if (index === idx) {
                if (name === 'type' && value === 'object') {
                    return { ...field, type: value, children: [{ key: '', type: 'string', description: '', example: '' }] };
                } else if (name === 'type' && value !== 'object') {
                    return { ...field, type: value, children: [] };
                } else {
                    return { ...field, [name]: value };
                }
            }
            return field;
        });
        setInvoiceData({ ...invoiceData, invoiceFields: newFields });
    };

    const handleAddField = () => {
        setInvoiceData({ ...invoiceData, invoiceFields: [...invoiceData.invoiceFields, { key: '', type: 'string', description: '', example: '', children: [] }] });
    };

    const handleRemoveField = (index) => {
        setInvoiceData({ ...invoiceData, invoiceFields: invoiceData.invoiceFields.filter((_, idx) => idx !== index) });
    };

    const handleAddChild = (parentIndex) => {
        const newFields = invoiceData.invoiceFields && invoiceData.invoiceFields.map((field, idx) => {
            if (parentIndex === idx && field.type === 'object') {
                return {
                    ...field,
                    children: [
                        ...field.children,
                        { key: '', type: 'string', description: '', example: '' }
                    ]
                };
            }
            return field;
        });
        setInvoiceData({ ...invoiceData, invoiceFields: newFields });
    };

    const handleChildChange = (parentIndex, childIndex, event) => {
        const { name, value } = event.target;

        if (value.length > 200) {
            // Optionally display an error message
            console.log("Input exceeds the maximum character limit of 200.");
            return;
        }

        const newFields = invoiceData.invoiceFields && invoiceData.invoiceFields.map((field, idx) => {
            if (parentIndex === idx && field.type === 'object') {
                const newChildren = field.children.map((child, cIdx) => {
                    if (childIndex === cIdx) {
                        return { ...child, [name]: value };
                    }
                    return child;
                });
                return { ...field, children: newChildren };
            }
            return field;
        });
        setInvoiceData({ ...invoiceData, invoiceFields: newFields });
    };

    const handleRemoveChild = (parentIndex, childIndex) => {
        const newFields = invoiceData.invoiceFields && invoiceData.invoiceFields.map((field, idx) => {
            if (parentIndex === idx && field.type === 'object') {
                const newChildren = field.children.filter((_, cIdx) => cIdx !== childIndex);
                return { ...field, children: newChildren };
            }
            return field;
        });
        setInvoiceData({ ...invoiceData, invoiceFields: newFields });
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const validTypes = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf'];
            if (!validTypes.includes(file.type)) {
                setErrors((prevErrors) => ({ ...prevErrors, invoiceFile: 'Invalid file type. Only jpg, jpeg, png, and pdf are allowed.' }));
                setInvoiceData({ ...invoiceData, invoiceFile: null });
                setFilePreview(null);
                event.target.value = null;
                return;
            } else if (file.size > 2 * 1024 * 1024) { // 2MB size limit
                setErrors((prevErrors) => ({ ...prevErrors, invoiceFile: 'File size must be less than 2MB.' }));
                setInvoiceData({ ...invoiceData, invoiceFile: null });
                setFilePreview(null);
                event.target.value = null;
                return;
            } else {
                setErrors((prevErrors) => ({ ...prevErrors, invoiceFile: '' }));
            }

            setInvoiceData({ ...invoiceData, invoiceFile: file });

            // Create a preview URL for images
            if (file.type.startsWith('image/')) {
                setFilePreview({ url: URL.createObjectURL(file), type: file.type });
            } else {
                setFilePreview({ name: file.name, url: URL.createObjectURL(file), type: file.type });
            }
        }
    };

    const handleInputChange = (e) => {
        const { id, value } = e.target;

        // Validation
        if (value.length > 200) {
            setErrors((prevErrors) => ({ ...prevErrors, [id]: `${id} cannot exceed 200 characters.` }));
            return;
        } else {
            setErrors((prevErrors) => ({ ...prevErrors, [id]: '' }));
        }

        setInvoiceData({ ...invoiceData, [id]: value });
    };

    const handleSubmitInternal = async (event) => {
        event.preventDefault();
        setLoading(true);
        simulateProgress();
        const getUserDetail = localStorage.getItem('user');
        const userDetail = JSON.parse(getUserDetail);

        const setData = {
            user: userDetail.id,
            invoice_name: invoiceData.invoiceName,
            invoice_type: invoiceData.selectInvoiceType,
            invoice_file: invoiceData.invoiceFile,
            invoice_fields: JSON.stringify(invoiceData.invoiceFields),
        };

        try {
            const res = await handleSubmit(setData);
            if (res) {
                toast.success('Document Extracted successfully');
                setProgress(100);
                setLoading(false);
            }
        } catch (error) {
            console.error('Upload error:', error);
            setLoading(false);
        }
    };

    return (
        <div className="container mx-auto px-4">
            <form onSubmit={handleSubmitInternal} className="p-6 bg-gray-50 rounded-lg mx-auto">
                <div className="mb-4 p-4 bg-white rounded-lg shadow">
                    <div className="grid gap-5 grid-cols-1 md:grid-cols-10">
                        <div className="md:col-span-5">
                            <InputBox
                                type="text"
                                id="invoiceName"
                                placeholder="Template Name"
                                value={invoiceData.invoiceName}
                                onChange={handleInputChange}
                                error={errors.invoiceName}
                                required
                            />
                        </div>
                        <div className="md:col-span-5">
                            <SelectorDropdown
                                id="selectInvoiceType"
                                value={invoiceData.selectInvoiceType}
                                onChange={handleInputChange}
                                options={invoiceType}
                                error={errors.selectInvoiceType}
                                placeholder={'Select Document Type'}
                                required
                            />
                        </div>
                    </div>
                </div>

                {/* Dynamic Fields */}
                {invoiceData.invoiceFields && invoiceData.invoiceFields.length > 0 && <div>
                    {invoiceData.invoiceFields.map((field, index) => (
                        <div key={index} className="mb-4 p-4 bg-white rounded-lg shadow">
                            <div className="flex mb-2">
                                <input
                                    type="text"
                                    name="key"
                                    value={field.key}
                                    onChange={(e) => handleFieldChange(index, e)}
                                    className="border border-gray-300 p-2 text-gray-600 py-1 rounded w-1/4 mr-2"
                                    placeholder="Key"
                                />
                                <select
                                    name="type"
                                    value={field.type}
                                    onChange={(e) => handleFieldChange(index, e)}
                                    className="border border-gray-300 p-2 text-gray-600 py-1 rounded w-1/4 mr-2"
                                >
                                    <option value="string">String</option>
                                    <option value="object">Object</option>
                                </select>
                                <input
                                    type="text"
                                    name="description"
                                    value={field.description}
                                    onChange={(e) => handleFieldChange(index, e)}
                                    className="border border-gray-300 px-2 py-1 text-gray-600 rounded w-1/2 mr-2"
                                    placeholder="Description"
                                />
                                <input
                                    type="text"
                                    name="example"
                                    value={field.example}
                                    onChange={(e) => handleFieldChange(index, e)}
                                    className="border border-gray-300 px-2 py-1 text-gray-600 rounded w-1/2"
                                    placeholder="Example"
                                />
                                <button
                                    type="button"
                                    className="text-red-500 hover:text-red-700 ml-2"
                                    onClick={() => handleRemoveField(index)}
                                >
                                    <LuMinusCircle size={20} />
                                </button>
                            </div>

                            {field.type === 'object' && (
                                <div className="ml-6 mt-2">
                                    {field.children.map((child, cIdx) => (
                                        <div key={cIdx} className="flex mb-2">
                                            <input
                                                type="text"
                                                name="key"
                                                value={child.key}
                                                onChange={(e) => handleChildChange(index, cIdx, e)}
                                                className="border border-gray-300 p-2 text-gray-600 py-1 rounded w-1/4 mr-2"
                                                placeholder="Child Key"
                                            />
                                            <select
                                                name="type"
                                                value={child.type}
                                                onChange={(e) => handleChildChange(index, cIdx, e)}
                                                className="border border-gray-300 p-2 text-gray-600 py-1 rounded w-1/4 mr-2"
                                            >
                                                <option value="string">String</option>
                                            </select>
                                            <input
                                                type="text"
                                                name="description"
                                                value={child.description}
                                                onChange={(e) => handleChildChange(index, cIdx, e)}
                                                className="border border-gray-300 px-2 py-1 text-gray-600 rounded w-1/2 mr-2"
                                                placeholder="Child Description"
                                            />
                                            <input
                                                type="text"
                                                name="example"
                                                value={child.example}
                                                onChange={(e) => handleChildChange(index, cIdx, e)}
                                                className="border border-gray-300 px-2 py-1 text-gray-600 rounded w-1/2"
                                                placeholder="Child Example"
                                            />
                                            <button
                                                type="button"
                                                className="text-red-500 hover:text-red-700 ml-2"
                                                onClick={() => handleRemoveChild(index, cIdx)}
                                            >
                                                <LuMinusCircle size={20} />
                                            </button>
                                        </div>
                                    ))}
                                    <button
                                        type="button"
                                        className="flex px-2 mt-4 rounded-full border border-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:opacity-50 text-primary-600 bg-white hover:bg-primary-500 hover:text-white"
                                        onClick={() => handleAddChild(index)}
                                    >
                                        Add Subitem <LuPlusCircle className='ml-1 mt-1' size={17} />
                                    </button>
                                </div>
                            )}
                        </div>
                    ))}
                    <button
                        type="button"
                        className="flex px-2 mt-4 rounded-full border border-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:opacity-50 text-primary-600 bg-white hover:bg-primary-500 hover:text-white"
                        onClick={handleAddField}
                    >
                        Add New Field <LuPlusCircle className='ml-1 mt-1' size={17} />
                    </button>
                </div>}
                <FileSelector
                    id="reference_image"
                    onChange={handleFileChange}
                    error={errors.invoiceFile}
                    preview={filePreview}
                />
                {/* {errors.invoiceFile && <p className="text-red-500 text-sm mt-1">{errors.invoiceFile}</p>}  */}
            </form>
            {loading && <ModalLoader progress={progress} message="Processing your invoice..." />}
            <ToastContainer />
        </div>
    );
};

export default InvoiceUpload;
