import React, { useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { IoArrowBack } from "react-icons/io5";
import { useDispatch } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import InvoiceView from '../InvoiceTemplate/InvoiceView';

const ExtractedData = () => {  
    const location = useLocation();
    const { extractedData } = location.state;
    
    const handleBackButtonClick = () => {
        window.history.back();
    }; 

    console.log('extractedData', extractedData);
    
    
    return (
        <div className="justify-center items-center ">
        <main className="p-4">
            <div className="grid grid-col-1 mt-6 mb-6 ">
                <h1 className="text-center text-4xl font-bold text-gray-500 dark:text-white md:text-3xl">Extracted Document</h1>
                <p className="text-center  text-gray-500 dark:text-gray-300">
                List of extracted document data!
                </p>
            </div>
        <div className="min-h-screen p-6 flex flex-col items-center">

            <div className="w-full max-w-4xl bg-white rounded-lg shadow-lg p-8"> 
                <div className='col-span-1'>
                    <button onClick={handleBackButtonClick} className="px-2 w-20 py-1 flex text-gray-600 border border-gray-300 bg-white hover:bg-gray-600 hover:text-white rounded-full focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"><IoArrowBack className='mt-1 mr-1' /> Back</button>
                </div>
                <InvoiceView updatedData={extractedData} />
            </div>
            <ToastContainer />
        </div>
        </main>
        </div>
    );
};

export default ExtractedData;
