import React from 'react';

const ContactMap = () => {
  return (
    <section className="bg-gradient-to-r from-primary-50 to-gray-50 dark:from-gray-800 dark:to-gray-900 py-16">
      <div className="container px-8 mx-auto">
        {/* Section Header */}
        <div className="text-center mb-12">
          <h2 className="text-3xl font-extrabold text-gray-800 dark:text-white">
            Visit Our Office
          </h2>
          <p className="mt-4 text-lg text-gray-600 dark:text-gray-300">
            We are located in the heart of Melbourne. Come visit us for a coffee and a chat!
          </p>
        </div>
        {/* Map Section */}
        <div className="mt-16 p-4 rounded-lg overflow-hidden shadow-lg bg-white dark:bg-gray-800">
          <iframe
            title="Office Location"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.835434509002!2d144.95373631531592!3d-37.816279779751594!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad642af0f11fd81%3A0xf0727db6d33f73a3!2s16122%20Collins%20Street%2C%20Melbourne%20VIC%203000%2C%20Australia!5e0!3m2!1sen!2sus!4v1615154594648!5m2!1sen!2sus"
            width="100%"
            height="450"
            style={{ border: 0, borderRadius: '8px' }}
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div>
      </div>
    </section>
  );
};

export default ContactMap;
