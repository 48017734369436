import React, { useState } from 'react';
import { Transition } from '@headlessui/react';
import { Link } from 'react-router-dom';

const HomePageHero = () => {
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <div className="relative bg-gray-900 text-white overflow-hidden">
      <div className="absolute inset-0">
        <img
          className="w-full h-full object-cover opacity-30"
          src="images/home-hero-2.jpg"
          alt="Background"
        />
        <div className="absolute inset-0 bg-gradient-to-r from-purple-600 via-purple-500 to-purple-600 opacity-90"></div>
      </div>
      <div className="relative mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-24 sm:py-32 lg:py-40">
        <Transition
          show={true}
          appear={true}
          enter="transform transition duration-700"
          enterFrom="opacity-0 translate-y-10"
          enterTo="opacity-100 translate-y-0"
        >
          <div className="text-center">
            <h1 className="text-4xl sm:text-5xl lg:text-6xl font-extrabold">
              <span className="block text-white">Extract and Structure Data</span>
              <span className="block text-white">
                from <span className="text-purple-300">Unstructured Documents</span>
              </span>
            </h1>
            <p className="mt-6 max-w-xl mx-auto text-lg sm:text-lg lg:text-lg text-white font-semibold">
              Extract, organize, and analyze data from invoices, receipts, bank statements, insurance forms, logistics documents, and more with our advanced AI-powered solution.
            </p>
            <div className="mt-10 flex flex-col sm:flex-row justify-center gap-4">
              <Link
                to="/signup"
                className="inline-flex items-center justify-center rounded-full bg-gradient-to-r from-purple-500 to-purple-700 py-3 px-8 text-base font-medium text-white hover:from-purple-700 hover:to-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-600 transition-transform transform hover:scale-105 shadow-lg"
              >
                Get Started
              </Link>
              <button
                onClick={toggleModal}
                className="inline-flex items-center justify-center rounded-full bg-gradient-to-r from-white to-gray-300 py-3 px-8 text-base font-medium text-purple-600 hover:from-gray-300 hover:to-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 transition-transform transform hover:scale-105 shadow-lg"
              >
                <svg aria-hidden="true" className="h-6 w-6 mr-2 fill-current" viewBox="0 0 24 24">
                  <path d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2Zm3.76 10.45-5 3a.5.5 0 0 1-.76-.43V9a.5.5 0 0 1 .76-.43l5 3a.5.5 0 0 1 0 .86Z" />
                </svg>
                Watch Video
              </button>
            </div>
          </div>
        </Transition>
      </div>

      {showModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75 transition-opacity duration-300">
          <div className="bg-white rounded-lg overflow-hidden shadow-lg transform transition-all w-full mx-4 sm:mx-auto sm:max-w-3xl">
            <div className="flex justify-end p-2">
              <button
                onClick={toggleModal}
                className="text-gray-600 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button> 
            </div>
            <div className="px-4 sm:px-8 pb-8">
              <div className="aspect-w-16 aspect-h-9">
                <iframe
                  className="w-full h-full"
                  src="https://www.youtube.com/embed/C6o-IPhe2ig?si=CXyuZhFF59_LrRCM"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HomePageHero;
