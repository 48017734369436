import React, { useState } from 'react';
import { FaUserPlus } from 'react-icons/fa';
import { IoArrowBack } from "react-icons/io5";
import { Link } from 'react-router-dom';

const AddTeamMember = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});

  const goBack = () => {
    window.history.back();
  };

  const validateForm = () => {
    const newErrors = {};
    if (!name) newErrors.name = 'Name is required';
    if (!email) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = 'Email is invalid';
    }
    if (!role) newErrors.role = 'Role is required';
    if (!password) {
      newErrors.password = 'Password is required';
    } else if (password.length < 6) {
      newErrors.password = 'Password must be at least 6 characters';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleAddMember = (e) => {
    e.preventDefault();
    if (!validateForm()) return;
  };

  return (
    <div className='p-4 min-h-screen'>
      <div className="grid grid-col-1 mt-6 mb-6 ">
        <h1 className="text-4xl font-bold text-gray-500 dark:text-white md:text-3xl">Add New Member</h1>
        <p className="text-gray-500 dark:text-gray-300">
          {/* Manage team, view members, and add new ones! */}
        </p>
      </div>
      <div className="max-w-5xl mx-auto p-6 bg-white rounded-lg shadow-md">
        <Link to={goBack} className="flex w-28 font-semibold items-center px-2 py-2 text-sm text-gray-600 border border-gray-300 rounded-md shadow-md hover:bg-gray-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2">
          <IoArrowBack className="mr-2" />
          Go Back
        </Link>
        <form onSubmit={handleAddMember}>
          <div className="md:mx-20 mt-4 grid grid-cols-1 gap-4 md:grid-cols-2">
            <div className="mt-4 md:col-span-1">
              <input
                type="text"
                id="name"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="w-full px-4 py-2 border text-gray-500 font-normal rounded-md focus:outline-none focus:ring-2 focus:ring-primary-400"
                required
              />
              {errors.name && <p className="text-red-500 text-sm">{errors.name}</p>}
            </div>
            <div className="mt-4 md:col-span-1">
              <input
                type="email"
                id="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full px-4 py-2 border text-gray-500 font-normal rounded-md focus:outline-none focus:ring-2 focus:ring-primary-400"
                required
              />
              {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
            </div>
            <div className="mt-4 md:col-span-1">
              <input
                type="text"
                id="role"
                placeholder="Role"
                value={role}
                onChange={(e) => setRole(e.target.value)}
                className="w-full px-4 py-2 border text-gray-500 font-normal rounded-md focus:outline-none focus:ring-2 focus:ring-primary-400"
                required
              />
              {errors.role && <p className="text-red-500 text-sm">{errors.role}</p>}
            </div>
            <div className="mt-4 md:col-span-1">
              <input
                type="password"
                id="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full px-4 py-2 border text-gray-500 font-normal rounded-md focus:outline-none focus:ring-2 focus:ring-primary-400"
                required
              />
              {errors.password && <p className="text-red-500 text-sm">{errors.password}</p>}
            </div>
          </div>
          <div className="flex justify-center mt-6">
            <button
              type="submit"
              className="px-6 py-2 text-white bg-primary-600 rounded-md shadow-md hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-400"
            >
              Add Member
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddTeamMember;
