import React from 'react';
import { Link } from 'react-router-dom';

const CallToActionSection = () => {
  return (
    <div className="m-4 md:m-12">
      <div className="relative pt-20 md:pt-20 md:pb-10"> 
        <div className="mx-auto">
          <div className="relative rounded-2xl px-6 py-10 bg-primary-100 overflow-hidden shadow-xl sm:px-12 sm:py-20">
            <div aria-hidden="true" className="absolute inset-0 -mt-72 sm:-mt-32 md:mt-0">
              <svg className="absolute inset-0 h-full w-full" preserveAspectRatio="xMidYMid slice" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 1463 360">
                <path className="text-primary-300 text-opacity-40" fill="currentColor" d="M-82.673 72l1761.849 472.086-134.327 501.315-1761.85-472.086z"></path>
                <path className="text-fuchsia-100 text-opacity-40" fill="currentColor" d="M-217.088 544.086L1544.761 72l134.327 501.316-1761.849 472.086z"></path>
              </svg>
            </div>
            <div className="relative flex flex-col items-center text-center">
              <h1 className="text-4xl font-extrabold text-gray-700 md:text-5xl">
                Get Started with DocumentAI
              </h1>
              <p className="mt-6 mx-auto max-w-2xl text-lg text-gray-700">
                Join thousands of users around the world who trust DocumentAI for efficient document processing and data visualization.
              </p>
              <div className="flex flex-wrap justify-center gap-6 mt-10">
                <Link to="/signup" className="inline-flex items-center justify-center rounded-full bg-gradient-to-r from-primary-500 to-primary-700 py-3 px-8 text-base font-medium text-white hover:from-primary-700 hover:to-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-600 transition-transform transform hover:scale-105">
                  <span className="relative text-base font-semibold text-white">Get Started</span>
                </Link>
                <Link to="/about" className="relative flex h-12 items-center justify-center px-8 before:absolute before:inset-0 before:rounded-full before:border before:border-transparent before:bg-gray-50 before:bg-gradient-to-b before:transition before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 dark:before:border-gray-700 dark:before:bg-gray-800 sm:w-max">
                  <span className="relative text-base font-semibold text-primary-500 dark:text-white">Learn More</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CallToActionSection;
