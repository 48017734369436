import React, { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { setLogoutFunction } from './axiosInstance';
import { logout as logoutAction } from '../redux/actions/authActions';
import { useDispatch } from 'react-redux';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(() => !!localStorage.getItem('token'));
  const navigate = useNavigate();  
  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsAuthenticated(true);
    }
    setLogoutFunction(logout); 
  }, []);

  const login = (res) => {
    localStorage.setItem('user', JSON.stringify(res.user));
    localStorage.setItem('token', res.access);
    localStorage.setItem('refreshToken', res.refresh);
    setIsAuthenticated(true);
    navigate('/dashboard');
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('refreshToken');
    setIsAuthenticated(false);
    dispatch(logoutAction()); 
    navigate('/signin');
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => React.useContext(AuthContext);
