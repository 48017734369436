import React, { useState } from 'react';
import { FaHome, FaTimes, FaCog } from 'react-icons/fa';
import { IoHomeOutline } from "react-icons/io5";
import { TbFileInvoice, TbLogout } from "react-icons/tb";
import { MdDocumentScanner } from "react-icons/md";
import { NavLink, useLocation } from 'react-router-dom';
import { useAuth } from '../../Common/AuthProvider';

const SideBar = ({ sidebarOpen, setSidebarOpen }) => {
  const location = useLocation();
  const { logout } = useAuth();

  const activeLink = (pathname) => {
    return location.pathname === pathname ? 'bg-gradient-to-r from-primary-500 to-primary-600 text-white' : '';
  };

  const handleLinkClick = () => {
    if (window.innerWidth <= 1024) { // adjust the width as per your breakpoint
      setSidebarOpen(false);
    }
  };

  return (
    <div>
      <aside id="default-sidebar" className={`fixed px-4 top-0 left-0 z-40 inset-y-0 left-0 bg-white h-screen shadow-lg w-64 transform ${sidebarOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out lg:translate-x-0 lg:inset-0`}>
        <div className="flex items-center justify-between p-4">
          <NavLink to="/" className="flex items-center" onClick={handleLinkClick}>
            <img src='/images/logo.png' alt="Logo" className="h-8" />
            <span className="self-center text-2xl ml-2 text-gray-800 font-extrabold whitespace-nowrap dark:text-white">
              DocumentAI
            </span>
          </NavLink>
          <button className="lg:hidden focus:outline-none" onClick={() => setSidebarOpen(!sidebarOpen)}>
            <FaTimes />
          </button>
        </div>
        <nav className="flex flex-col justify-between h-full">
          <div className='mt-4 lg:mt-8'>
            <NavLink to="/dashboard" className={`flex items-center mt-1 py-2 px-6 rounded text-gray-500 bg-gradient-to-r text-base/[27px] ${activeLink('/dashboard')} hover:from-primary-200 hover:to-primary-300 focus:ring-primary-500 transition-colors duration-300`} onClick={handleLinkClick}>
              <IoHomeOutline size="20" className="mr-3" /> Dashboard
            </NavLink> 
            <NavLink to="/invoices" className={`flex items-center mt-1 py-2 px-6 rounded text-gray-500  bg-gradient-to-r text-base/[27px] ${activeLink('/invoices')} ${activeLink('/add-invoice')} ${activeLink('/extracted-template-data')} ${activeLink('/edit-invoice')} hover:from-primary-200 hover:to-primary-300 focus:ring-primary-500 transition-colors duration-300`} onClick={handleLinkClick}>
              <TbFileInvoice  size="20" className="mr-3" /> Templates
            </NavLink>
            <NavLink to="/scanned-invoices" className={`flex items-center mt-1 py-2 px-6 rounded text-gray-500  bg-gradient-to-r text-base/[27px] ${activeLink('/new-scan-invoice')} ${activeLink('/scanned-invoices')} ${activeLink('/extracted-data')} hover:from-primary-200 hover:to-primary-300 focus:ring-primary-300 transition-colors duration-300`} onClick={handleLinkClick}>
              <MdDocumentScanner size="20" className="mr-3" /> Scan Document
            </NavLink>
          </div>
          <div className="mb-16">
            <NavLink to="/profile" className={`flex items-center mt-1 py-2 px-6 rounded text-gray-500  bg-gradient-to-r text-base/[27px] ${activeLink('/profile')} hover:from-primary-200 hover:to-primary-300 focus:ring-primary-300 transition-colors duration-300`} onClick={handleLinkClick}>
              <FaCog className="mr-3" /> Settings
            </NavLink>
            <NavLink onClick={() => {logout(); handleLinkClick();}} className={`flex bg-primary-200  mt-1 items-center py-2 px-6 rounded mb-3 text-primary-600 font-semibold text-base/[27px] hover:from-primary-600 hover:to-primary-700 focus:ring-primary-500 transition-colors duration-300`}>
              <TbLogout size={20} className="font-bold mr-3" /> Logout
            </NavLink>
          </div>
        </nav>
      </aside>
    </div>
  );
};

export default SideBar;
