import React, { useState, useRef, useEffect } from 'react';
import { FaChevronDown, FaFileCsv, FaFileExcel, FaFileCode, FaTrashAlt } from 'react-icons/fa';
import { downloadJSON, downloadCSV, downloadExcel } from './downloadUtils';

const DropdownForm = ({ buttonLabel, options, data }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef();

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []); 

  const handleOptionClick = (option) => {
    if (option.onClick) {
      option.onClick();
    } else {
      switch (option.type) {
        case 'csv':
        downloadCSV(data);
        break;
      case 'excel':
        downloadExcel(data);
        break;
      case 'json':
        downloadJSON(data);
        break;
      default:
        break;
      }
    }
    setIsOpen(false);
  };

  return (
    <div className="inline-block text-left" ref={dropdownRef}>
      <button
        onClick={handleToggle}
        className="px-2 py-1 text-primary-600 border border-primary-600 bg-white hover:bg-primary-600 hover:text-white rounded-full focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 flex items-center transition duration-150 ease-in-out"
      >
        {buttonLabel}
      </button>
      {isOpen && (
        <div className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50">
          <div className="py-1">
            {options.map((option, index) => (
              <button
                key={index}
                onClick={() => handleOptionClick(option)}
                className="block px-4 py-2 w-full text-sm font-semibold text-gray-600 hover:text-primary-600 hover:bg-primary-100 flex items-center transition duration-150 ease-in-out"
              >
                {option.icon && <span className="mr-2">{option.icon}</span>}
                {option.label}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default DropdownForm;
