import React from 'react';
import Lottie from 'react-lottie';
import loadingAnimation from './loadingAnimation.json';

const ModalLoader = ({ progress, message }) => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: loadingAnimation,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };

    return (
        <div className="fixed inset-0 md:left-20 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white md:ml-40 p-6 rounded-lg shadow-lg">
                <Lottie options={defaultOptions} height={150} width={150} />
                <p className="text-center text-primary-600 font-semibold mt-4">{message}</p>
                <div className="w-full bg-gray-200 rounded-full mt-4">
                    <div
                        className="bg-primary-600 text-xs font-medium text-primary-100 text-center p-0.5 leading-none rounded-full"
                        style={{ width: `${progress}%` }}
                    >
                        {progress}%
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalLoader;
