import React from 'react';
import Chart from 'react-apexcharts';

const SalesGraphCard = ({ salesData }) => {
  const series = [{
    name: 'Sales',
    data: salesData
  }];

  const options = {
    chart: {
      type: 'bar',
      height: 350,
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        columnWidth: '20%',
        endingShape: 'rounded'
      }
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      categories: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
      labels: {
        style: {
          colors: '#9333ea',
          fontSize: '12px'
        }
      }
    },
    yaxis: {
      title: {
        text: 'Sales',
        style: {
          color: '#9333ea',
          fontSize: '12px'
        }
      }
    },
    fill: {
      opacity: 1
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val;
        }
      }
    },
    grid: {
      borderColor: '#e2e8f0'
    },
    colors: ['#9333ea']
  };

  return (
    <div className="rounded-lg border border-stroke bg-white p-6 shadow-md dark:border-strokedark dark:bg-boxdark">
      <div className="flex justify-between items-start mb-4">
        <div>
          <h3 className="text-lg font-semibold text-gray-800 dark:text-white">Sales Overview</h3>
          <p className="text-sm text-gray-500 dark:text-gray-400">Last Week Performance</p>
        </div>
        <div className="text-right">
          <p className="text-2xl font-bold text-gray-800 dark:text-white">{`${salesData ? salesData.reduce((acc, val) => acc + Number(val), 0) : 0 } `}</p>
          <p className="text-sm text-red-500 flex items-center">
            <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24">
              <path d="M17 13l-5 5m0 0l-5-5m5 5V6"></path>
            </svg>
            0%
          </p>
        </div>
      </div>
      <Chart options={options} series={series} type="bar" height={350} />
    </div>
  );
};

export default SalesGraphCard;
