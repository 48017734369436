import React from 'react';
import { FaCheckCircle, FaSyncAlt, FaCode, FaShieldAlt, FaUserAlt, FaCogs, FaLongArrowAltRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const ServiceSection = () => {
  return (
    <section className="overflow-hidden bg-white py-8">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pl-8 lg:pt-4 flex items-center">
            <div className="lg:max-w-lg">
              <h2 className="inline-block px-3 py-1 text-sm tracking-wider text-primary-600 font-bold uppercase rounded-full bg-primary-50">Enhance Efficiency</h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">AI-Powered Document Processing</p>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                Our cutting-edge AI technology automates your document processing, saving you time and reducing errors.
              </p>
              <div className="mt-10 space-y-6">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <span className="flex items-center justify-center h-12 w-12 rounded-md bg-primary-100">
                      <FaCheckCircle className="h-4 w-4 text-primary-500" />
                    </span>
                  </div>
                  <div className="ml-4">
                    <h4 className="text-lg leading-6 font-medium text-gray-900">Accurate Data Extraction</h4>
                    <p className="mt-2 text-base text-gray-600">
                      Utilize our AI to ensure accurate and efficient data extraction from your documents.
                    </p>
                  </div>
                </div>

                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <span className="flex items-center justify-center h-12 w-12 rounded-md bg-primary-100">
                      <FaSyncAlt className="h-4 w-4 text-primary-500" />
                    </span>
                  </div>
                  <div className="ml-4">
                    <h4 className="text-lg leading-6 font-medium text-gray-900">Effortless Automation</h4>
                    <p className="mt-2 text-base text-gray-600">
                      Easily integrate our solution with your existing systems and workflows.
                    </p>
                  </div>
                </div>

                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <span className="flex items-center justify-center h-12 w-12 rounded-md bg-primary-100">
                      <FaCode className="h-4 w-4 text-primary-500" />
                    </span>
                  </div>
                  <div className="ml-4">
                    <h4 className="text-lg leading-6 font-medium text-gray-900">Developer-Friendly API</h4>
                    <p className="mt-2 text-base text-gray-600">
                      Our API is well-documented and easy to use, making integration a breeze for developers.
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-10 flex items-center gap-x-6">
                <Link to="/signup"
                  className="rounded-md bg-primary-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600">Get Started Now</Link>
                <Link to="/contact" className="flex text-sm font-semibold leading-6 text-gray-700">Connect with us<span aria-hidden="true"><FaLongArrowAltRight className='mt-1.5 ml-1 text-gray-600' /></span></Link>
              </div>
            </div>
          </div>
          <div className="flex items-center">
            <img src="images/service-1.png" alt="Product screenshot" className="max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 w-full  md:-ml-4 lg:-ml-0" />
          </div>
        </div>
      </div>

      <div className="bg-white mt-16">
        <div className="max-w-7xl mx-auto">
          <div className="container mx-auto px-6 p-6 pt-0 bg-white">
            <div className="flex flex-wrap my-12">
              <div className="w-full border-b md:w-1/2 md:border-r lg:w-1/3 p-8">
                <div className="flex items-center mb-6">
                  <FaCheckCircle className="h-6 w-6 text-primary-600" />
                  <div className="ml-4 text-base font-semibold">Automated Data Extraction</div>
                </div>
                <p className="leading-loose text-gray-500">
                  Automatically extract key data from your documents with high accuracy using our AI-driven technology.
                </p>
              </div>

              <div className="w-full border-b md:w-1/2 lg:w-1/3 lg:border-r p-8">
                <div className="flex items-center mb-6">
                  <FaSyncAlt className="h-6 w-6 text-primary-600" />
                  <div className="ml-4 text-base font-semibold">Enterprise-Ready Solutions</div>
                </div>
                <p className="leading-loose text-gray-500">
                  Scale your business effortlessly with our robust, enterprise-ready Document Extraction solutions.
                </p>
              </div>

              <div className="w-full border-b md:w-1/2 md:border-r lg:w-1/3 lg:border-r-0 p-8">
                <div className="flex items-center mb-6">
                  <FaCode className="h-6 w-6 text-primary-600" />
                  <div className="ml-4 text-base font-semibold">Seamless Integration</div>
                </div>
                <p className="leading-loose text-gray-500">
                  Integrate our Document Extraction technology seamlessly with your existing systems and workflows.
                </p>
              </div>

              <div className="w-full border-b md:w-1/2 lg:w-1/3 lg:border-r lg:border-b-0 p-8">
                <div className="flex items-center mb-6">
                  <FaShieldAlt className="h-6 w-6 text-primary-600" />
                  <div className="ml-4 text-base font-semibold">Highly Secure</div>
                </div>
                <p className="leading-loose text-gray-500">
                  Keep your data secure with our robust security measures and compliance with industry standards.
                </p>
              </div>

              <div className="w-full border-b md:w-1/2 md:border-r md:border-b-0 lg:w-1/3 lg:border-b-0 p-8">
                <div className="flex items-center mb-6">
                  <FaUserAlt className="h-6 w-6 text-primary-600" />
                  <div className="ml-4 text-base font-semibold">User-Friendly Interface</div>
                </div>
                <p className="leading-loose text-gray-500">
                  Our platform features a user-friendly interface that makes it easy to manage and track your documents.
                </p>
              </div>

              <div className="w-full md:w-1/2 lg:w-1/3 p-8">
                <div className="flex items-center mb-6">
                  <FaCogs className="h-6 w-6 text-primary-600" />
                  <div className="ml-4 text-base font-semibold">Customizable Workflows</div>
                </div>
                <p className="leading-loose text-gray-500">
                  Tailor our Document Extraction workflows to fit your unique business needs and processes.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServiceSection;
