import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaFileInvoice } from 'react-icons/fa';
import Tabs from './Components/Tabs';
import DashboardCard from './Components/DashboardCard';
import SalesGraphCard from './Components/SalesGraphCard';
import CustomerListCard from './Components/CustomerListCard';
import { useDispatch } from 'react-redux';
import { fetchDashboardData } from '../redux/actions/invoiceAction';
import { set } from 'react-hook-form';

const DashboardHome = () => {

  const [data, setData] = useState({
    total_sales: 0,
    invoice_count: 0,
    customer_count: 0,
    sales_trend: 0.00,
    invoice_trend: 0.00,
    customer_trend: 0.00,
  });

  const [salesData, setSalesData] = useState([]);
  const [customerData, setCustomerData] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const invoiceData = await dispatch(fetchDashboardData());
        
        if (invoiceData) {
          setData({
            total_sales: invoiceData.total_sales,
            invoice_count: invoiceData.invoice_count,
            customer_count: invoiceData.customer_count,
            sales_trend: invoiceData.sales_trend,
            invoice_trend: invoiceData.invoice_trend,
            customer_trend: invoiceData.customer_trend,
          });

          setSalesData(invoiceData.sales_data);
          setCustomerData(invoiceData.recent_customers);
        } else {
          console.log('Error fetching data');
        }
      } catch (error) {
        console.log(error.message);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="flex">

      <div className="flex-1">
        <main className="p-4">
          <div className="grid grid-cols-1 md:grid-cols-2 mt-6 mb-6 ">
            <div>
              <h1 className="text-4xl font-bold text-gray-500 dark:text-white md:text-3xl">DocumentAI Insight</h1>
              <p className="text-gray-500 dark:text-gray-300">
                Efficiently manage and extract data from your invoices!
              </p>
            </div>
            <div>
              <div className="flex justify-enter mt-4 md:justify-end">
                <Link to="/add-invoice" className="flex items-center px-8 py-2 text-white bg-primary-600 rounded-full shadow-md hover:from-primary-600 hover:to-primary-600 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">
                  <FaFileInvoice className="mr-2" />
                  Add Template
                </Link>
              </div>
            </div>
          </div>

          {/* <Tabs /> */}
          <div className="grid grid-cols-1 mt-5">
            <DashboardCard data={data} />
          </div>
          <div className="grid gap-5 grid-cols-1 md:grid-cols-10 mt-5">
            <div className="md:col-span-7">
              <SalesGraphCard salesData={salesData} />
            </div>
            <div className="md:col-span-3">
              <CustomerListCard customerData={customerData} />
            </div>
          </div>

        </main>
        {/* <Footer /> */}
      </div>
    </div>
  );
};

export default DashboardHome;
