import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { authReducer } from './reducers/authReducers';
import { getInvoiceTypeListReducer, getInvoiceTypeReducer, uploadInvoiceTypeReducer } from './reducers/invoiceReducers';
import { loadingReducer } from './reducers/loadingReducer';

const rootReducer = combineReducers({
    loading: loadingReducer,
    auth: authReducer,
    uploadedInvoiceType: uploadInvoiceTypeReducer, 
    invoiceType: getInvoiceTypeReducer,
    invoiceTypeList: getInvoiceTypeListReducer,
});

const initialState = {
    auth: { userInfo: null },
    uploadedInvoiceType: {},
    invoiceType: {},
    invoiceTypeList: [],
};

const store = configureStore({
    reducer: rootReducer,
    preloadedState: initialState,
});

export default store;
