import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { FaFileInvoice, FaSearch, FaRegTrashAlt, FaRegEdit } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { fetchUserInvoiceType, deleteInvoiceType } from '../../../redux/actions/invoiceAction';
import { useDispatch } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Container from '../../../Components/Container';
import ContentHeader from '../../../Components/ContentHeader';
import Card from '../../../Components/Card';

const Invoices = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [filter, setFilter] = useState('');
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const itemsPerPage = 10;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await dispatch(fetchUserInvoiceType());
        setInvoices(data);
      } catch (error) {
        console.log(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleEdit = (invoiceId, getextractedData, invoiceFile) => {
    const extractedData = JSON.parse(getextractedData);
    navigate('/edit-invoice', { state: { invoiceId, extractedData, invoiceFile } });
  }

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const handleDelete = (invoiceId) => {
    confirmAlert({
      title: 'Confirm Deletion',
      message: 'Are you sure you want to delete this invoice? It will delete all related invoices.',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            try {
              await dispatch(deleteInvoiceType(invoiceId));
              setInvoices(invoices.filter(invoice => invoice.id !== invoiceId));
              localStorage.removeItem('invoiceTypesList');
              localStorage.removeItem('invoiceList');
              toast.success('Invoice deleted successfully');
            } catch (error) {
              toast.error('Error deleting invoice');
              console.error('Delete error:', error);
            }
          }
        },
        {
          label: 'No',
          onClick: () => { }
        }
      ]
    });
  };

  const handleViewInvoice = (getextractedData) => {
    const extractedData = JSON.parse(getextractedData);
    navigate('/extracted-template-data', { state: { extractedData } });
  };

  const filteredInvoices = Array.isArray(invoices) ? invoices.filter((invoice) =>
    (invoice.invoice_name && invoice.invoice_name.toLowerCase().includes(filter.toLowerCase())) ||
    (invoice.invoice_type && invoice.invoice_type.toLowerCase().includes(filter.toLowerCase())) ||
    (invoice.created_at && invoice.created_at.includes(filter)) ||
    (invoice.status && invoice.status.toLowerCase().includes(filter.toLowerCase()))
  ) : [];

  const offset = currentPage * itemsPerPage;
  const currentInvoices = filteredInvoices.slice(offset, offset + itemsPerPage);

  return (
    <Container>
      <ContentHeader heading="Document Template" style="text-left" subheading="List of all document templates" />
      <Card>
        <div className="flex justify-between items-center mb-6">
          <div className="relative w-1/3">
            <FaSearch className="absolute top-2.5 left-3 text-gray-500" />
            <input
              type="text"
              placeholder="Filter templates"
              className="w-full p-2 pl-10 border border-gray-300 rounded-md focus:ring focus:ring-primary-200 focus:border-primary-500"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            />
          </div>
          <Link to="/add-invoice" className="flex items-center px-8 py-2 rounded-full shadow-lg text-white bg-gradient-to-r from-primary-500 to-primary-600 hover:from-primary-600 hover:to-primary-700">
            <FaFileInvoice className="mr-2" />
            Add Template
          </Link>
        </div>
        <div className="overflow-x-auto">
          <table className="min-w-full rounded-lg">
            <thead className="bg-gradient-to-r from-primary-100 to-primary-100">
              <tr>
                <th className="py-3 px-5 text-left text-sm font-semibold text-gray-700">Template Name</th>
                <th className="py-3 px-5 text-left text-sm font-semibold text-gray-700">Type</th>
                <th className="py-3 px-5 text-left text-sm font-semibold text-gray-700">Date</th>
                <th className="py-3 px-5 text-left text-sm font-semibold text-gray-700">Status</th>
                <th className="py-3 px-5 text-left text-sm font-semibold text-gray-700">Action</th>
              </tr>
            </thead>
            <tbody>
              {currentInvoices.length > 0 ? (
                currentInvoices.map((invoice, index) => (
                  <tr key={index} className="border-b hover:bg-gray-50">
                    <td className="py-3 px-5 text-sm text-gray-800"><button onClick={() => handleViewInvoice(invoice.extracted_text)} className='text-primary-700 font-semibold'>{invoice.invoice_name}</button></td>
                    <td className="py-3 px-5 text-sm text-gray-800">{invoice.invoice_type_name}</td>
                    <td className="py-3 px-5 text-sm text-gray-800">{formatDate(invoice.created_at)}</td>
                    <td className="py-3 px-5 text-sm">
                      <span className={`inline-block px-3 py-1 rounded-full text-sm ${invoice.status === 'Active' ? 'bg-green-100 text-green-800' : invoice.status === 'Processing' ? 'bg-yellow-100 text-yellow-800' : 'bg-red-100 text-red-800'}`}>
                        {invoice.status}
                      </span>
                    </td>
                    <td className="py-3 px-5 text-sm">
                      <button onClick={() => handleEdit(invoice.id, invoice.extracted_text, invoice.invoice_file)} className="px-4 py-1 text-primary-600 border border-primary-600 bg-white hover:bg-primary-600 hover:text-white rounded-full focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">Edit</button>
                      <button
                        onClick={() => handleDelete(invoice.id)}
                        className="px-4 ml-2 py-2 text-red-500 border border-red-500 bg-white hover:bg-red-500 hover:text-white rounded-full focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                      >
                        <FaRegTrashAlt size={12} />
                      </button>
                    </td>
                  </tr>
                ))) : (
                <tr>
                  <td colSpan="5" className="py-10 text-center">
                    <div className="flex flex-col items-center">
                      <FaFileInvoice className="mb-3 text-6xl text-gray-400" />
                      <p className="text-lg font-medium text-gray-600">No Invoices Found</p>
                      <p className="text-sm text-gray-500">Try adding new invoice!</p>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="mt-6">
          {currentInvoices.length > 0 && (
            <ReactPaginate
              previousLabel={'Previous'}
              nextLabel={'Next'}
              breakLabel={'...'}
              pageCount={Math.ceil(filteredInvoices.length / itemsPerPage)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={'flex justify-center space-x-2'}
              pageClassName={'px-3 py-1 border border-gray-300 text-gray-600 rounded-full hover:bg-gray-100 hover:text-primary-600'}
              previousClassName={'px-3 py-1 border border-gray-300 text-gray-600 rounded-full hover:bg-gray-100 hover:text-primary-600'}
              nextClassName={'px-3 py-1 border border-gray-300 text-gray-600 rounded-full hover:bg-gray-100 hover:text-primary-600'}
              breakClassName={'px-3 py-1 border border-gray-300 text-gray-600 rounded-full hover:bg-gray-100 hover:text-primary-600'}
              activeClassName={'bg-primary-600 text-white'}
            />)}
        </div>
      </Card>
    </Container>
  );
};

export default Invoices;
