import React, { useState } from 'react';
import InvoiceUpload from './InvoiceUpload';
import InvoiceSetup from './InvoiceSetup';
import InvoiceProcess from './InvoiceProcess';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UploadInvoiceType, UpdateInvoiceType } from '../../../redux/actions/invoiceAction';
import Button from '../../../Components/Button';
import Card from '../../../Components/Card';
import ModalLoader from '../../../Components/ModalLoader';

const steps = [
  { id: '01', name: 'Upload' },
  { id: '02', name: 'Setup' },
  { id: '03', name: 'Processing' },
];

const Stepper = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [invoiceData, setInvoiceData] = useState({
    invoiceName: '',
    selectInvoiceType: '',
    invoiceFields: '',
    invoiceFile: null,
  });
  const [invoiceId, setInvoiceId] = useState(null);
  const [extractedData, setExtractedData] = useState(null);
  const [errors, setErrors] = useState({});
  const [invoiceFile, setInvoiceFile] = useState(null);
  const [updatedData, setUpdatedData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleNext = async () => {
    if (currentStep === 0) {
      const validationErrors = validate();
      setErrors(validationErrors);
      if (Object.keys(validationErrors).length === 0) {
        await handleSubmit();
        setCurrentStep(currentStep + 1);
      }
    } else if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePrev = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handlePrevExtracted = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
      setExtractedData(updatedData);
    }
  };

  const validate = () => {
    const errors = {};
    
    // Template name validation
    if (!invoiceData.invoiceName) {
        errors.invoiceName = 'Invoice name is required.';
    } else if (invoiceData.invoiceName.length > 200) {
        errors.invoiceName = 'Invoice name must be less than 200 characters.';
    }

    // Invoice type validation
    if (!invoiceData.selectInvoiceType) {
        errors.selectInvoiceType = 'Invoice type is required.';
    }

    // Invoice file validation
    if (!invoiceData.invoiceFile) {
        errors.invoiceFile = 'Invoice file is required.';
    } else {
        const file = invoiceData.invoiceFile;
        const validTypes = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf'];
        if (!validTypes.includes(file.type)) {
            errors.invoiceFile = 'Invalid file type. Only jpg, jpeg, png, and pdf are allowed.';
        } else if (file.size > 2 * 1024 * 1024) { // 2MB size limit
            errors.invoiceFile = 'File size must be less than 2MB.';
        }
    }

    return errors;
};

  const simulateProgress = () => {
    const increment = () => {
      setProgress((prevProgress) => {
        const nextProgress = prevProgress + 1;
        return nextProgress >= 95 ? 100 : nextProgress;
      });
    };
    const interval = setInterval(increment, 150);
    return () => clearInterval(interval);
  };

  const handleSubmit = async () => {
    setLoading(true);
    simulateProgress();
    const getUserDetail = localStorage.getItem('user');
    const userDetail = JSON.parse(getUserDetail);
    console.log('JSON.stringify(invoiceData.invoiceFields)', JSON.stringify(invoiceData.invoiceFields));
    
    const setData = {
      user: userDetail.id,
      invoice_name: invoiceData.invoiceName,
      invoice_type: invoiceData.selectInvoiceType,
      invoice_fields: JSON.stringify(invoiceData.invoiceFields),
      invoice_file: invoiceData.invoiceFile,
    };

    try {
      const res = await dispatch(UploadInvoiceType(setData));
      if (res) {
        toast.success('Document Extracted successfully');
        try {
          setProgress(100);
          const extractedText = JSON.parse(res.extracted_text);
          setExtractedData(extractedText);
          setInvoiceId(res.id);
          setInvoiceFile(res.invoice_file);
          setCurrentStep(currentStep + 1);
          setLoading(false);
        } catch (parseError) {
          console.error('Error parsing extracted text:', parseError);
        }
      }
    } catch (error) {
      console.error('Upload error:', error);
    }
  };

  const removeValues = (obj) => {
    const result = {};
    Object.keys(obj).forEach(key => {
        if (Array.isArray(obj[key])) {
            result[key] = obj[key].map(item => (typeof item === 'object' && item !== null) ? removeValues(item) : '');
        } else if (typeof obj[key] === 'object' && obj[key] !== null) {
            result[key] = removeValues(obj[key]);
        } else {
            result[key] = '';
        }
    });
    return JSON.stringify(result);
  };

  const submitChanges = async () => {
    try {
      console.log('JSON.stringify(updatedData)', JSON.stringify(updatedData));
      console.log('removeValues(updatedData)', removeValues(updatedData));
      
      const setupdatedData = {
        extracted_text: JSON.stringify(updatedData),
        extracted_label: removeValues(updatedData),
      };
      const res = await dispatch(UpdateInvoiceType(invoiceId, setupdatedData));
      if (res) {
        toast.success('Invoice type updated successfully');
        localStorage.removeItem('invoiceTypesList');
        setTimeout(() => {
          navigate('/invoices');
        }, 3000);
      }
    } catch (error) {
      console.error('Submit changes error:', error);
    }
  };

  return (
    <Card className="max-w-4xl my-10 p-6">
      <ToastContainer />
      <div className="relative flex justify-between items-center">
        {steps.map((step, index) => (
          <div key={index} className="relative flex-1 flex flex-col items-center">
            <div className="relative flex items-center justify-center w-10 h-10 rounded-full border-2 bg-white z-10">
              <div className={`absolute inset-0 flex items-center justify-center rounded-full ${index <= currentStep ? 'bg-primary-600 text-white border-primary-600' : 'border-gray-300 text-gray-500'}`}>
                {step.id}
              </div>
            </div>
            {index < steps.length - 1 && (
              <div className="absolute left-16 md:left-32 top-9 transform -translate-y-4 w-full h-0.5 bg-gray-300 z-0">
                <div className={`w-full h-0.5 ${index < currentStep ? 'bg-primary-600' : 'bg-gray-300'}`}></div>
              </div>
            )}
            <div className={`mt-2 h-10 md:h-6 text-sm font-medium text-center ${index <= currentStep ? 'text-primary-600' : 'text-gray-500'}`}>
              {step.name}
            </div>
          </div>
        ))}
      </div>
      <div className="mt-10">
        <div className="mb-6">
          {currentStep === 0 && <InvoiceUpload invoiceData={invoiceData} setInvoiceData={setInvoiceData} errors={errors} setErrors={setErrors} handleSubmit={handleSubmit} />}
          {currentStep === 1 && <InvoiceSetup extractedData={extractedData} invoiceFile={invoiceFile} setUpdatedData={setUpdatedData} />}
          {currentStep === 2 && <InvoiceProcess updatedData={updatedData} submitChanges={submitChanges} />}
        </div>
        <div className="flex justify-between">
          {currentStep === steps.length - 1 && (
            <Button
              onClick={handlePrevExtracted}
              disabled={currentStep === 0}
              className="text-gray-700 bg-gray-200 hover:bg-gray-300 focus:ring-gray-500"
            >
              Previous
            </Button>
          )}
          {currentStep !== steps.length - 1 && (
            <>
              <Button
                onClick={handlePrev}
                disabled={currentStep === 0}
                className="text-gray-700 bg-gray-200 hover:bg-gray-300 focus:ring-gray-500"
              >
                Previous
              </Button>
              <Button
                onClick={handleNext}
                disabled={currentStep === steps.length - 1}
                className="text-white bg-gradient-to-r from-primary-500 to-primary-600 hover:from-primary-600 hover:to-primary-700 focus:ring-primary-500"
              >
                Next
              </Button>
            </>
          )}
        </div>
      </div>
      {loading && <ModalLoader progress={progress} message="Extracting data from invoice..." />}
      <ToastContainer />
      </Card>
  );
};

export default Stepper;
