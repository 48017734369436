import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

export const downloadJSON = (data, filename = 'invoice.json') => {
  const blob = new Blob([JSON.stringify(data, null, 2)], { type: 'application/json' });
  saveAs(blob, filename);
};

export const downloadCSV = (data, filename = 'invoice.csv') => {
  const csvData = convertToCSV(data);
  const blob = new Blob([csvData], { type: 'text/csv' });
  saveAs(blob, filename);
};

export const downloadExcel = (data, filename = 'invoice.xlsx') => {
  const worksheetData = Array.isArray(data) ? data : [data];
  const worksheet = XLSX.utils.json_to_sheet(worksheetData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  XLSX.writeFile(workbook, filename);
};

const convertToCSV = (data) => {
  const array = Array.isArray(data) ? data : [data];
  const keys = Array.from(new Set(array.flatMap(item => Object.keys(flattenObject(item)))));
  const csvContent = [
    keys.join(','), // Header row
    ...array.map(row => {
      const flatRow = flattenObject(row);
      return keys.map(key => flatRow[key] ? `"${flatRow[key]}"` : '').join(',');
    })
  ].join('\n');
  return csvContent;
};

const flattenObject = (obj, parent = '', res = {}) => {
  for (let key in obj) {
    const propName = parent ? `${parent}_${key}` : key;
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      if (Array.isArray(obj[key])) {
        res[propName] = '[object]';
      } else {
        flattenObject(obj[key], propName, res);
      }
    } else {
      res[propName] = obj[key];
    }
  }
  return res;
};
