import React, { useState, useEffect } from 'react';
import { LuMinusCircle, LuPlusCircle } from "react-icons/lu";
import { v4 as uuidv4 } from 'uuid';
import { FILE_URL } from '../../../redux/env';
import { Document, Page, pdfjs } from 'react-pdf';
import PdfViewerBox from '../../Components/PdfViewerBox';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.mjs',
    import.meta.url,
  ).toString();

const InvoiceSetup = ({ extractedData, invoiceFile, setUpdatedData }) => {
    const [formData, setFormData] = useState([]);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    useEffect(() => {
        if (!extractedData) return;
        console.log('extractedData', extractedData);
        

        const mapDataToFields = (data, parentKey = '') => {
            return Object.entries(data).map(([key, value]) => {
                const fullKey = parentKey ? `${parentKey}_${key}` : key;
                if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
                    return {
                        id: uuidv4(),
                        key: fullKey,
                        type: 'object',
                        children: mapDataToFields(value, ``),
                    };
                } else if (Array.isArray(value) && value.length > 0) {
                    return {
                        id: uuidv4(),
                        key: fullKey,
                        type: 'array',
                        children: value.map((item, index) => ({
                            id: uuidv4(),
                            key: `${fullKey}_${index}`,
                            type: typeof item === 'object' ? 'object' : typeof item,
                            value: item,
                            children: typeof item === 'object' ? mapDataToFields(item, ``) : [],
                        })),
                    };
                } else {
                    return {
                        id: uuidv4(),
                        key: fullKey,
                        type: typeof value,
                        value: value,
                    };
                }
            });
        };

        const initialFormData = mapDataToFields(extractedData);
        setFormData(initialFormData);
    }, [extractedData]);

    useEffect(() => {
        const generateUpdatedData = (fields) => {
            if (!Array.isArray(fields) || fields.length === 0) {
                return {}; // Return an empty object if fields is not an array or is empty
            }
        
            return fields.reduce((acc, field) => {
                if (field.type === 'object') {
                    acc[field.key] = generateUpdatedData(field.children || []);
                } else if (field.type === 'array') {
                    acc[field.key] = field.children ? field.children.map(child => child.type === 'object' ? generateUpdatedData(child.children || []) : child.value) : [];
                } else {
                    acc[field.key] = field.value;
                }
                return acc;
            }, {});
        };
        

        const updatedData = generateUpdatedData(formData);

        setUpdatedData(updatedData);
    }, [formData, setUpdatedData]);



    const handleFieldChange = (fieldId, key, value) => {
        if (value.length > 500) {
            // You can also display an error message here if needed
            console.log("Input exceeds the maximum character limit of 500.");
            return;
        }

        const updateField = (fields) => {
            return fields.map(field => {
                if (field.id === fieldId) {
                    if (field.type === 'object' || field.type === 'array') {
                        return {
                            ...field,
                            [key]: value,
                            children: updateField(field.children),
                        };
                    } else {
                        return {
                            ...field,
                            [key]: value,
                        };
                    }
                } else if (field.children) {
                    return {
                        ...field,
                        children: updateField(field.children),
                    };
                }
                return field;
            });
        };

        setFormData(prevData => updateField(prevData));
    };

    const handleAddField = (parentId = null, parentKey = '') => {
        const newField = {
            id: uuidv4(),
            key: parentKey ? `${parentKey}_newField` : `newField_${uuidv4()}`,
            type: 'string',
            value: '',
        };

        if (parentId) {
            const addField = (fields) => {
                return fields.map(field => {
                    if (field.id === parentId && (field.type === 'object' || field.type === 'array')) {
                        return {
                            ...field,
                            children: [...field.children, newField],
                        };
                    } else if (field.children) {
                        return {
                            ...field,
                            children: addField(field.children),
                        };
                    }
                    return field;
                });
            };
            setFormData(prevData => addField(prevData));
        } else {
            setFormData(prevData => [...prevData, newField]);
        }
    };

    const handleRemoveField = (fieldId) => {
        const removeField = (fields) => {
            return fields.filter(field => field.id !== fieldId).map(field => {
                if (field.children) {
                    return {
                        ...field,
                        children: removeField(field.children),
                    };
                }
                return field;
            });
        };

        setFormData(prevData => removeField(prevData));
    };

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setPageNumber(1);
    }

    const renderFields = (fields) => {
        if (!fields || fields.length === 0) {
            return null;
        }
    
        return fields.map((field) => (
            <div key={field.id} className="m-2 mb-4 p-2 bg-white rounded-lg shadow">
                <div className="flex">
                    <input
                        type="text"
                        name="key"
                        value={field.key}
                        onChange={(e) => handleFieldChange(field.id, 'key', e.target.value)}
                        className="border border-gray-300 p-2 text-gray-600 py-1 rounded w-1/2 mr-2"
                        placeholder="Key"
                    />
                    {field.type !== 'object' && field.type !== 'array' && (
                        <input
                            type="text"
                            name="value"
                            value={field.value || ''}
                            onChange={(e) => handleFieldChange(field.id, 'value', e.target.value)}
                            className="border border-gray-300 px-2 py-1 text-gray-600 rounded w-1/2"
                            placeholder="Value"
                        />
                    )}
                    <button
                        type="button"
                        className="text-red-500 hover:text-red-700 ml-2"
                        onClick={() => handleRemoveField(field.id)}
                    >
                        <LuMinusCircle size={20} />
                    </button>
                </div>
                {field.type === 'object' && (
                    <>
                        <div className="ml-4">{renderFields(field.children || [])}</div>
                        <button
                            type="button"
                            className={`flex px-2 mt-4 rounded-full border border-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:opacity-50 text-primary-600 bg-white hover:bg-primary-500 hover:text-white`}
                            onClick={() => handleAddField(field.id, field.key)}
                        >
                            Add New Field <LuPlusCircle className='ml-1 mt-1' size={17} />
                        </button>
                    </>
                )}
                {field.type === 'array' && (
                    <div className="ml-4">
                        {field.children && field.children.length > 0 ? (
                            field.children.map(child => renderFields([child]))
                        ) : null}
                    </div>
                )}
            </div>
        ));
    };
    

    return (
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:px-12">
            <div className="col-span-2">
                <h3 className="text-lg font-semibold text-gray-800 mb-4">Document Setup</h3>
                <div className='max-h-96 overflow-y-scroll border-y-4 custom-scrollbardoc rounded bg-gray-50'>
                    {renderFields(formData)}
                </div>
                <button
                    type="button"
                    className="flex px-2 mt-4 rounded-full border border-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 text-primary-600 bg-white hover:bg-primary-500 hover:text-white"
                    onClick={() => handleAddField()}
                >
                    Add Parent Field <LuPlusCircle className='ml-1 mt-1' size={17} />
                </button>
            </div>
            <div className="rounded col-span-1">
                {invoiceFile && invoiceFile.endsWith('.pdf') ? (
                    <div className="w-xl md:mt-12 justify-center rounded border">
                        <PdfViewerBox
                            fileUrl={FILE_URL + invoiceFile} // Pass the URL of the PDF
                            width="100%"  // Set the width you want
                            height="416px"  // Set the height you want
                        />
                        <div className="flex justify-center my-4">
                        <a href={FILE_URL + invoiceFile} target="_blank" rel="noopener text-xs noreferrer" className=" text-primary-500 bg-primary-100 px-3 py-1 rounded-full">
                            View PDF
                        </a>
                        </div>
                    </div>
                ) : (
                    <img src={FILE_URL + invoiceFile} alt="Invoice" className="w-80 md:mt-12 justify-center rounded" />
                )}
            </div>
        </div>
    );
};

export default InvoiceSetup;
