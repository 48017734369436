import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { FaFileInvoice, FaSearch, FaTrashAlt } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import { deleteInvoice, fetchInvoiceData } from '../../../redux/actions/invoiceAction';
import { FaFileCsv, FaFileExcel, FaFileCode } from 'react-icons/fa6';
import { MdDocumentScanner } from "react-icons/md";
import DropdownForm from '../../Components/DropdownForm';
import DateRangePicker from '../../Components/DateRangePicker';
import { parseISO, isWithinInterval, format } from 'date-fns';
import Container from '../../../Components/Container';
import ContentHeader from '../../../Components/ContentHeader';
import Card from '../../../Components/Card';

const ScannedInvoices = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [filter, setFilter] = useState('');
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const itemsPerPage = 10;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await dispatch(fetchInvoiceData());
        setInvoices(data);
      } catch (error) {
        console.log(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch]);

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const handleViewInvoice = (getextractedData) => {
    const extractedData = JSON.parse(getextractedData);
    navigate('/extracted-data', { state: { extractedData } });
  };

  const handleDelete = (invoiceId) => {
    confirmAlert({
      title: 'Confirm Deletion',
      message: 'Are you sure you want to delete this document?',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            try {
              await dispatch(deleteInvoice(invoiceId));
              setInvoices(invoices.filter(invoice => invoice.id !== invoiceId));
              localStorage.removeItem('invoiceList');
              localStorage.removeItem('dashboardData');
              toast.success('Document deleted successfully');
            } catch (error) {
              toast.error('Error deleting document');
              console.error('Delete error:', error);
            }
          }
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  };

  const filteredInvoices = Array.isArray(invoices)
    ? invoices.filter((invoice) => {
        const invoiceDate = invoice.invoice_date ? parseISO(invoice.invoice_date) : null;
        const isWithinDateRange =
          (!startDate || !invoiceDate || isWithinInterval(invoiceDate, { start: startDate, end: endDate }));
        const matchesFilter =
          (invoice.invoice_no && invoice.invoice_no.toLowerCase().includes(filter.toLowerCase())) ||
          (invoice.customer_name && invoice.customer_name.toLowerCase().includes(filter.toLowerCase())) ||
          (invoice.customer_address && invoice.customer_address.toLowerCase().includes(filter.toLowerCase())) ||
          (invoice.invoice_type && invoice.invoice_type.toLowerCase().includes(filter.toLowerCase())) ||
          (invoice.status && invoice.status.toLowerCase().includes(filter.toLowerCase()));
        return isWithinDateRange && matchesFilter;
      })
    : [];

  const offset = currentPage * itemsPerPage;
  const currentInvoices = filteredInvoices.slice(offset, offset + itemsPerPage);

  return (
    <Container>
      <ContentHeader heading="Scan Documents" style="text-left" subheading="List of scanned documents!" />
      <Card>
      <div className="flex justify-between items-center mb-6">
        <div className="relative w-1/3">
          <FaSearch className="absolute top-2.5 left-3 text-gray-500" />
          <input
            type="text"
            placeholder="Filter documents"
            className="w-full p-2 pl-10 border border-gray-300 rounded-md focus:ring focus:ring-primary-200 focus:border-primary-500"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          />
        </div>
        {/* <DateRangePicker dateRange={dateRange} setDateRange={setDateRange} /> */}
        <Link to="/new-scan-invoice" className="flex items-center px-3 md:px-8 py-2 rounded-full shadow-lg text-white bg-gradient-to-r from-primary-500 to-primary-600 hover:from-primary-600 hover:to-primary-700">
          <MdDocumentScanner size={22} className="mr-2" />
          Scan Document
        </Link>
      </div>
      <div className='overflow-x-auto'>
        <table className="min-w-full bg-white rounded-lg">
          <thead className="bg-gradient-to-r from-primary-100 to-primary-100">
            <tr>
              <th className="py-3 px-5 text-left text-sm font-semibold text-gray-700">Invoice No.</th>
              <th className="py-3 px-5 text-left text-sm font-semibold text-gray-700">Customer Name</th>
              <th className="py-3 px-5 text-left text-sm font-semibold text-gray-700">Address</th>
              <th className="py-3 px-5 text-left text-sm font-semibold text-gray-700">Date</th>
              <th className="py-3 px-5 text-left text-sm font-semibold text-gray-700">Total Amount</th>
              <th className="py-3 px-5 text-left text-sm font-semibold text-gray-700">Document Type</th>
              <th className="py-3 px-5 text-left text-sm font-semibold text-gray-700">Action</th>
            </tr>
          </thead>
          <tbody>
            {currentInvoices.length > 0 ? (
              currentInvoices.map((invoice, index) => (
                <tr key={index} className="border-b hover:bg-gray-50">
                  <td className="py-3 px-5 text-sm text-gray-800"><button onClick={() => handleViewInvoice(invoice.extracted_text)} className='text-primary-700 font-semibold'>{invoice.invoice_no}</button></td>
                  <td className="py-3 px-5 text-sm text-gray-800">{invoice.customer_name}</td>
                  <td className="py-3 px-5 text-sm text-gray-800">{invoice.customer_address}</td>
                  <td className="py-3 px-5 text-sm text-gray-800">{invoice.invoice_date}</td>
                  <td className="py-3 px-5 text-sm text-gray-800">{invoice.total_amount}</td>
                  <td className="py-3 px-5 text-sm text-gray-800">{invoice.user_invoice_type_name}</td>
                  <td className="py-3 px-5 text-sm">
                    <DropdownForm
                      buttonLabel={'Options'}
                      options={[
                        { label: 'View Data', type: 'View', icon: <FaFileCsv size={20} className="text-gray-500 hover:text-primary-500"/>, onClick: () => handleViewInvoice(invoice.extracted_text) },
                        { label: 'Export as CSV', type: 'csv', icon: <FaFileCsv size={20} className="text-gray-500 hover:text-primary-500"/> },
                        { label: 'Export as Excel', type: 'excel', icon: <FaFileExcel size={20} className="text-gray-500 hover:text-primary-500"/> },
                        { label: 'Export as JSON', type: 'json', icon: <FaFileCode size={20} className="text-gray-500 hover:text-primary-500"/> },
                        { label: 'Delete', type: 'delete', icon: <FaTrashAlt size={18} className="text-gray-500 hover:text-primary-500"/>, onClick: () => handleDelete(invoice.id) }
                      ]}
                      data={JSON.parse(invoice.extracted_text)}
                    />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="7" className="py-10 text-center">
                  <div className="flex flex-col items-center">
                    <FaFileInvoice className="mb-3 text-6xl text-gray-400" />
                    <p className="text-lg font-medium text-gray-600">No Scanned Document Found</p>
                    <p className="text-sm text-gray-500">Try adding scan document!</p>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="mt-6">
        {currentInvoices.length > 0 && (
          <ReactPaginate
            previousLabel={'Previous'}
            nextLabel={'Next'}
            breakLabel={'...'}
            pageCount={Math.ceil(filteredInvoices.length / itemsPerPage)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName={'flex justify-center space-x-2'}
            pageClassName={'px-3 py-1 border border-gray-300 text-gray-600 rounded-full hover:bg-gray-100 hover:text-primary-600'}
            previousClassName={'px-3 py-1 border border-gray-300 text-gray-600 rounded-full hover:bg-gray-100 hover:text-primary-600'}
            nextClassName={'px-3 py-1 border border-gray-300 text-gray-600 rounded-full hover:bg-gray-100 hover:text-primary-600'}
            breakClassName={'px-3 py-1 border border-gray-300 text-gray-600 rounded-full hover:bg-gray-100 hover:text-primary-600'}
            activeClassName={'bg-primary-600 text-white'}
          />
        )}
      </div> 
      </Card>
    </Container>
  );
};

export default ScannedInvoices;
