import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from '../Frontend/page/Home';
import About from '../Frontend/page/About';
import Services from '../Frontend/page/Service';
import Contact from '../Frontend/page/Contact';
import SigninPage from '../Frontend/page/Auth/Signin'; 
import ForgetPassword from '../Frontend/page/Auth/ForgetPassword'; 
import EmailVerification from '../Frontend/page/Auth/EmailVerification'; 
import PrivacyPolicy from '../Frontend/page/PrivacyPolicy';
import TermsAndConditions from '../Frontend/page/TermsAndConditions ';
import SignupPage from '../Frontend/page/Auth/Signup';
import CreateNewPassword from '../Frontend/page/Auth/CreateNewPassword';
import DashboardHome from '../Dashboard/DashboardHome';
import FrontendLayout from '../Layouts/FrontendLayout';
import DashboardLayout from '../Layouts/DashboardLayout';
import AddInvoice from '../Dashboard/Page/InvoiceTemplate/AddInvoice';
import Invoices from '../Dashboard/Page/InvoiceTemplate/Invoices';
import EditInvoice from '../Dashboard/Page/InvoiceTemplate/EditInvoice';
import Team from '../Dashboard/Page/Team';
import AddTeamMember from '../Dashboard/Page/Team/AddTeamMember';
import Profile from '../Dashboard/Page/Profile';
import ProtectedRoute from './ProtectedRoute'; // Import the ProtectedRoute component
import EmailVerified from '../Frontend/page/Auth/EmailVerified';
import NewScan from '../Dashboard/Page/ScanInvoice/NewScan';
import ExtractedData from '../Dashboard/Page/ScanInvoice/ExtractedData';
import ScannedInvoices from '../Dashboard/Page/ScanInvoice/ScannedInvoices';

const RouteList = () => {
  return (
    <Routes>
      <Route element={<FrontendLayout />}>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/signin" element={<SigninPage />} />
        <Route path="/reset-password" element={<ForgetPassword />} />
        <Route path="/create-new-password" element={<CreateNewPassword />} />
        <Route path="/signup" element={<SignupPage />} />
        <Route path="/email-verification" element={<EmailVerification />} />
        <Route path="/email-verified" element={<EmailVerified />} />
        <Route path="/service" element={<Services />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/term-and-condition" element={<TermsAndConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      </Route>
      <Route element={<DashboardLayout />}>
        <Route path="/dashboard" element={<ProtectedRoute element={<DashboardHome />} />} />
        <Route path="/add-invoice" element={<ProtectedRoute element={<AddInvoice />} />} />
        <Route path="/scanned-invoices" element={<ProtectedRoute element={<ScannedInvoices />} />} />
        <Route path="/new-scan-invoice" element={<ProtectedRoute element={<NewScan />} />} />
        <Route path="/extracted-template-data" element={<ProtectedRoute element={<ExtractedData />} />} />
        <Route path="/extracted-data" element={<ProtectedRoute element={<ExtractedData />} />} />
        <Route path="/edit-invoice" element={<ProtectedRoute element={<EditInvoice />} />} />
        <Route path="/invoices" element={<ProtectedRoute element={<Invoices />} />} />
        <Route path="/team" element={<ProtectedRoute element={<Team />} />} />
        <Route path="/add-member" element={<ProtectedRoute element={<AddTeamMember />} />} />
        <Route path="/profile" element={<ProtectedRoute element={<Profile />} />} />
      </Route>
    </Routes>
  );
};

export default RouteList;
