import React from 'react';

const ContentHeader = ({ heading, style, subheading }) => {
    return (
        <div className={`grid grid-col-1 mt-6 mb-6 ${style}`}>
            <h1 className="text-2xl md:text-4xl font-bold text-gray-500 dark:text-white md:text-3xl">{heading}</h1>
            <p className="text-gray-500 dark:text-gray-300">
            {subheading}
            </p>
        </div>
                
    );
}

export default ContentHeader;