import React, { useState } from 'react';

const FeaturesSection = () => {
  const [selectedTab, setSelectedTab] = useState('data-extraction');

  const tabs = [
    {
      id: 'data-extraction',
      title: 'Data Extraction',
      description: "Extract and digitize data from invoices, receipts, bank statements, and more.",
      imageSrc: './images/document-extracted-data.png'
    },
    {
      id: 'template-creation',
      title: 'Set New Template',
      description: "Easily create and customize templates for different document types.",
      imageSrc: './images/create-template.png'
    },
    {
      id: 'template-list',
      title: 'List of Templates',
      description: "Manage and organize all your templates in one place.",
      imageSrc: './images/list-of-create-template.png'
    },
    {
      id: 'dashboard-reporting',
      title: 'Dashboard & Reporting',
      description: "Generate comprehensive reports and gain insights with our intuitive dashboard.",
      imageSrc: './images/documentai-dashboard.png'
    }
  ];
  

  const selectedTabData = tabs.find(tab => tab.id === selectedTab);

  return (
    <section id="features" aria-label="Features for DocumentAI Box" className="bg-gradient-to-r from-primary-100 to-fuchsia-100 relative overflow-hidden bg-primary-600 pb-28 pt-20 sm:py-22">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 relative">
        <div className="max-w-1xl md:mx-auto md:text-center xl:max-w-none">
          <h2 className="font-display text-3xl tracking-tight font-bold text-gray-600 sm:text-4xl md:text-4xl">Everything you need to manage your documents.</h2>
          <p className="mt-6 text-md font-semibold tracking-tight text-gray-500">Streamline your document processing with our comprehensive features.</p>
        </div>
        <div className="mt-16 grid grid-cols-1 items-center gap-y-2 pt-10 sm:gap-y-6 md:mt-20 lg:grid-cols-12 lg:pt-0">
          <div className="-mx-4 flex overflow-x-auto pb-4 sm:mx-0 sm:overflow-visible sm:pb-0 lg:col-span-5">
            <div className="relative z-10 flex gap-x-4 whitespace-nowrap px-4 sm:mx-auto sm:px-0 lg:mx-0 lg:block lg:gap-x-0 lg:gap-y-1 lg:whitespace-normal" role="tablist" aria-orientation="horizontal">
              {tabs.map(tab => (
                <div key={tab.id} className={`group relative rounded-full px-4 py-1 lg:rounded-l-xl lg:rounded-r-none lg:p-4 ${selectedTab === tab.id ? 'bg-gradient-to-r from-primary-500 to-primary-700' : 'bg-transparent hover:bg-white/40'}`}>
                  <h3>
                    <button
                      className={`font-display text-md font-semibold md:font-bold ${selectedTab === tab.id ? 'text-gray-100 lg:text-gray-50' : 'text-gray-600 hover:text-gray-800 lg:text-gray-600'}`}
                      role="tab"
                      onClick={() => setSelectedTab(tab.id)}
                    >
                      <span className="absolute inset-0 rounded-full lg:rounded-l-xl lg:rounded-r-none"></span>
                      {tab.title}
                    </button>
                  </h3>
                  <p className={`mt-1 hidden text-base lg:block ${selectedTab === tab.id ? 'text-gray-50' : 'text-gray-600 group-hover:text-gray-600'}`}>{tab.description}</p>
                </div>
              ))}
            </div>
          </div>
          <div className="lg:col-span-7">
            {selectedTabData && (
              <div role="tabpanel">
                <div className="relative sm:px-6 lg:hidden">
                  <div className="absolute -inset-x-4 bottom-[-4.25rem] top-[-6.5rem] bg-white/10 ring-1 ring-inset ring-white/10 sm:inset-x-0 sm:rounded-t-xl"></div>
                  <p className="relative mx-auto max-w-2xl text-base text-gray-700 md:text-white sm:text-center">{selectedTabData.description}</p>
                </div>
                <div className="mt-10 md:py-12 w-full md:w-[45rem] overflow-hidden rounded-xl bg-slate-50 shadow-xl shadow-primary-900/20 sm:w-auto lg:mt-0 lg:w-[47.8125rem]">
                  <img alt={selectedTabData.title} fetchpriority="high" decoding="async" className="w-full" style={{ color: 'transparent' }} src={selectedTabData.imageSrc} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeaturesSection;
