import React from 'react';
import '../../../style/Style.css';
import Button from '../../../Components/Button';

const InvoiceProcess = ({ updatedData, submitChanges }) => {
  const renderField = (label, value) => (
    <div className="flex justify-between items-start my-2 p-2 bg-white">
      <span className="font-semibold text-gray-700 mr-2">{label}</span>
      <span className="text-gray-600">{value}</span>
    </div>
  );

  const renderObject = (label, obj) => (
    <div className="bg-white shadow-md rounded-md p-6 mb-6">
      <h3 className="text-xl font-bold text-primary-600 mb-4">{label}</h3>
      <div className="pl-4">
        {Object.entries(obj).map(([subKey, subValue]) => (
          <div key={subKey}>
            {typeof subValue === 'object' && subValue !== null ? (
              // If the value is an object or array, render it recursively
              Array.isArray(subValue) ? (
                renderArray(subKey.replace(/_/g, ' '), subValue)
              ) : (
                renderObject(subKey.replace(/_/g, ' '), subValue)
              )
            ) : (
              renderField(subKey.replace(/_/g, ' '), subValue)
            )}
          </div>
        ))}
      </div>
    </div>
  );

  const renderArray = (label, array) => (
    <div className="bg-white shadow-md rounded-md p-6 mb-6">
      <h3 className="text-xl font-bold text-primary-600 mb-4">{label}</h3>
      {array.map((item, index) => (
        <div key={index} className="mb-4 border-b pb-4 last:border-b-0">
          {typeof item === 'object' ? (
            <>
              <h4 className="text-lg font-semibold text-gray-800 mb-2">Item {index + 1}</h4>
              <div className="pl-4">
                {Object.entries(item).map(([subKey, subValue]) => renderField(subKey.replace(/_/g, ' '), subValue))}
              </div>
            </>
          ) : (
            <div className="pl-4">
              {renderField(`Note ${index + 1}`, item)}
            </div>
          )}
        </div>
      ))}
    </div>
  );

  const renderSection = (label, fields) => (
    <div className="mb-6">
      <h3 className="text-xl font-bold text-primary-600 mb-4">{label}</h3>
      {fields}
    </div>
  );

  const fields = Object.entries(updatedData).reduce((acc, [key, value]) => {
    if (Array.isArray(value)) {
      acc.arrays.push(renderArray(key.replace(/_/g, ' '), value));
    } else if (typeof value === 'object' && value !== null) {
      acc.sections.push(renderObject(key.replace(/_/g, ' '), value));
    } else {
      acc.general.push(renderField(key.replace(/_/g, ' '), value));
    }
    return acc;
  }, { general: [], sections: [], arrays: [] });

  return (
    <div className="max-w-4xl mx-auto my-10 p-6 bg-primary-50 shadow-lg rounded-lg">
      <h2 className="text-3xl font-bold text-center text-gray-800 mb-8">Document Details</h2>
      <div>
        {fields.general.length > 0 && renderSection('General Information', fields.general)}
        {fields.sections}
        {fields.arrays}
      </div> 
      <div className="flex justify-center mt-10">
        <Button onClick={submitChanges}>Submit Changes</Button> 
      </div>
    </div>
  );
};

export default InvoiceProcess;
