import React, { useState } from 'react';
import { AiOutlineMail, AiOutlinePhone, AiOutlineInfoCircle } from 'react-icons/ai';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import Modal from './Modal';
import { contactMessage } from '../../../redux/actions/authActions';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ContactUs = () => {
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    try {
      await dispatch(contactMessage(data));
      setIsSubmitted(true);
      reset();
    } catch (error) {
      console.error('Failed to submit your message. Please try again.');
    }
  };

  const handleCloseModal = () => {
    setIsSubmitted(false);
  };

  return (
    <section className="py-16 lg:py-24 bg-gray-100">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-white shadow-lg rounded-lg p-8 -mt-32 md:-mt-40 lg:p-16">
          <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label htmlFor="name" className="block text-sm font-medium text-gray-900">First Name</label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  {...register('name', { 
                    required: 'First Name is required', 
                    maxLength: { value: 200, message: 'First Name must be less than 200 characters' }
                  })}
                  className={`block w-full px-5 py-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-primary-400 dark:focus:border-primary-400 focus:ring-primary-400 focus:outline-none focus:ring focus:ring-opacity-40 ${errors.name ? 'border-red-500' : ''}`}
                  placeholder="Name"
                />
                {errors.name && <p className="text-red-500 text-sm mt-1">{errors.name.message}</p>}
              </div>
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-900">Your Email</label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  {...register('email', { 
                    required: 'Email is required', 
                    maxLength: { value: 200, message: 'Email must be less than 200 characters' },
                    pattern: { value: /^\S+@\S+$/i, message: 'Invalid email address' } 
                  })}
                  className={`block w-full px-5 py-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-primary-400 dark:focus:border-primary-400 focus:ring-primary-400 focus:outline-none focus:ring focus:ring-opacity-40 ${errors.email ? 'border-red-500' : ''}`}
                  placeholder="you@example.com"
                />
                {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email.message}</p>}
              </div>
            </div> 
            <div>
              <label htmlFor="message" className="block text-sm font-medium text-gray-900">Your Message</label>
              <textarea
                name="message"
                id="message"
                rows="4"
                {...register('message', { 
                  required: 'Message is required', 
                  maxLength: { value: 400, message: 'Message must be less than 400 characters' }
                })}
                className={`block w-full px-5 py-3 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-primary-400 dark:focus:border-primary-400 focus:ring-primary-400 focus:outline-none focus:ring focus:ring-opacity-40 ${errors.message ? 'border-red-500' : ''}`}
                placeholder="Write your message here"
              ></textarea>
              {errors.message && <p className="text-red-500 text-sm mt-1">{errors.message.message}</p>}
            </div>
            <div className="text-center">
              <button
                type="submit"
                className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-full shadow-sm text-white bg-gradient-to-r from-primary-500 to-primary-700 hover:from-primary-700 hover:to-primary-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 transition-transform transform hover:scale-105"
              >
                Send Message
              </button>
            </div>
          </form>
          <div className="mt-12 grid grid-cols-1 md:grid-cols-3 gap-6 text-center">
            <div>
              <div className="flex justify-center items-center w-12 h-12 bg-primary-100 text-primary-600 rounded-full mb-4 mx-auto">
                <AiOutlineMail className="w-6 h-6" />
              </div>
              <h3 className="text-lg font-medium text-gray-900">Email Us</h3>
              <p className="mt-2 font-semibold text-sm text-gray-600">
                Email us for general queries, including marketing and partnership opportunities.
              </p>
              <p className="mt-2 text-md font-semibold text-primary-600">help@documentai.com</p>
            </div>
            <div>
              <div className="flex justify-center items-center w-12 h-12 bg-primary-100 text-primary-600 rounded-full mb-4 mx-auto">
                <AiOutlinePhone className="w-6 h-6" />
              </div>
              <h3 className="text-lg font-medium text-gray-900">Call Us</h3>
              <p className="mt-2 font-semibold text-sm text-gray-600">
                Call us to speak to a member of our team. We are always happy to help.
              </p>
              <p className="mt-2 text-md font-semibold text-primary-600">+1 (555) 123-4567</p>
            </div>
            <div>
              <div className="flex justify-center items-center w-12 h-12 bg-primary-100 text-primary-600 rounded-full mb-4 mx-auto">
                <AiOutlineInfoCircle className="w-6 h-6" />
              </div>
              <h3 className="text-lg font-medium text-gray-900">Support</h3>
              <p className="mt-2 font-semibold text-sm text-gray-600">
                For support related queries, including technical issues and system troubleshooting.
              </p>
              <p className="mt-2 text-md font-semibold text-primary-600">support@documentai.com</p>
            </div>
          </div>
        </div>
      </div> 
      <Modal isOpen={isSubmitted} onClose={handleCloseModal}>
        <div className="p-6 text-center">
          <h2 className="text-2xl font-semibold mb-4">Thank you!</h2>
          <p>Your message has been successfully sent. We will get back to you shortly.</p>
          <button
            onClick={handleCloseModal}
            className="mt-6 inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-full shadow-sm text-white bg-gradient-to-r from-primary-500 to-primary-700 hover:from-primary-700 hover:to-primary-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 transition-transform transform hover:scale-105"
          >
            Close
          </button>
        </div>
      </Modal> 
      <ToastContainer />  
    </section>
  );
};

export default ContactUs;
