// components/common/Button.jsx
import React from 'react';

const Button = ({ type, children, onClick, disabled, className, ...props }) => {
    return (
        <button
            type={type}
            onClick={onClick}
            disabled={disabled}
            className={`px-4 py-2 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:opacity-50 ${className ? className : 'text-white bg-gradient-to-r from-primary-500 to-primary-600 hover:from-primary-600 hover:to-primary-700 focus:ring-primary-500'}`}
            {...props}
        >
            {children}
        </button>
    );
};

export default Button;
