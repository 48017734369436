import React from 'react';

const SelectorDropdown = ({ id, value, onChange, options, placeholder, error, ...props }) => {
    return (
        <div className="my-4">
            <select
                id={id}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                className={`w-full px-4 py-2 text-gray-500 font-normal border rounded-md focus:outline-none focus:ring-2 focus:ring-primary-400 ${error ? 'border-red-500' : 'border-gray-300'}`}
                {...props}
            >
                {placeholder && <option value="">{placeholder}</option>}
                {options && options.map((option) => (
                    <option key={option.id ? option.id : option.id } value={option.id ? option.id : option.id}>
                        {option.name? option.name : option.invoice_name}
                    </option>
                ))}
            </select>
            {error && <p className="text-red-500 text-xs mt-2">{error}</p>}
        </div>
    );
};

export default SelectorDropdown;
