import React, { useState } from 'react';
import Stepper from './Stepper';
import Container from '../../../Components/Container';
import ContentHeader from '../../../Components/ContentHeader';
import { ToastContainer } from 'react-toastify';
import Card from '../../../Components/Card';

const AddInvoice = () => {

    return (
        <Container>
            <ContentHeader heading="Settup New Document Template" style="text-center pt-4" subheading="Complete all process it will take few seconds!" />
            <ToastContainer />
            <Stepper />
        </Container>
    );
};

export default AddInvoice;
