import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';

const PdfViewerBox = ({ fileUrl, width = '100%', height = '500px' }) => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    const handleNextPage = () => {
        if (pageNumber < numPages) {
            setPageNumber(pageNumber + 1);
        }
    };

    const handlePreviousPage = () => {
        if (pageNumber > 1) {
            setPageNumber(pageNumber - 1);
        }
    };

    return (
        <div className="pdf-container custom-scrollbardoc rounded bg-gray-50" style={{ width, height, overflow: 'auto' }}>
            <Document
                file={fileUrl}
                onLoadSuccess={onDocumentLoadSuccess}
                renderMode="canvas"
                className="pdf-document"
            >
                <Page pageNumber={pageNumber} renderTextLayer={false} renderAnnotationLayer={false} />
            </Document>

            <div className="pdf-controls">
                <button onClick={handlePreviousPage} disabled={pageNumber <= 1}>
                    Previous
                </button>
                <span>
                    Page {pageNumber} of {numPages}
                </span>
                <button onClick={handleNextPage} disabled={pageNumber >= numPages}>
                    Next
                </button>
            </div>
        </div>
    );
};

export default PdfViewerBox;
