import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaAngleRight } from "react-icons/fa6";
import axios from 'axios';
import { API_URL } from '../../redux/env';
import SubscribeModal from '../../Components/SubscribeModal';

const currentYear = new Date().getFullYear();

const Footer = () => {

  const [email, setEmail] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState('');

  const handleSubscribe = async () => {
    if (!email) {
      setError('Email is required.');
      return;
    } else if (email.length > 200) {
      setError('Email must be less than 200 characters.');
      return;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setError('Please enter a valid email address.');
      return;
    } else {
      setError('');
    }

    try {
      await axios.post(`${API_URL}/auth/subscribe/`, { email });
      setEmail('');
      setIsModalOpen(true);
    } catch (error) {
      console.error('Subscription failed:', error);
      setError('Subscription failed. Please try again.');
    }
  };

  return (
    <footer className="bg-gray-800 text-gray-300">
      <div className="container mx-auto py-12 px-4 md:px-12 lg:px-24">
        <div className="flex flex-wrap justify-between">
          <div className="w-full md:w-1/3 mb-8 md:mb-0">
            <Link to="/" className="flex items-center space-x-3 mb-4">
              <img src="/images/logo.png" className="h-12" alt="Logo" />
              <span className="text-2xl font-extrabold text-white">Document.AI</span>
            </Link>
            <p className="text-gray-400 text-sm leading-relaxed">
              Document.AI is a cutting-edge tool for automating invoice processing and data visualization. Our AI-powered technology ensures accurate data extraction, streamlining your workflow and enhancing productivity.
            </p>
            <div className="mt-4">
              <Link
                to="/signup"
                className="inline-flex items-center justify-center rounded-full bg-gradient-to-r from-primary-500 to-primary-700 py-3 px-8 text-base font-medium text-white hover:from-primary-700 hover:to-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-600 transition-transform transform hover:scale-105 shadow-lg"
              >
                Get Started
              </Link>
            </div>
          </div>

          <div className="w-full md:w-1/5 mb-8 md:mb-0">
            <h2 className="text-xl font-semibold text-white mb-4">Company</h2>
            <ul className="space-y-2 text-gray-400">
              <li>
                <Link to="/about" className="hover:text-primary-400 flex items-center">
                  <FaAngleRight className='mr-2 text-primary-400 opacity-80' />
                  Who We Are
                </Link>
              </li>
              <li>
                <Link to="/service" className="hover:text-primary-400 flex items-center">
                  <FaAngleRight className='mr-2 text-primary-400 opacity-80' />
                  Our Expertise
                </Link>
              </li>
              <li>
                <Link to="/contact" className="hover:text-primary-400 flex items-center">
                  <FaAngleRight className='mr-2 text-primary-400 opacity-80' />
                  Connect with Us
                </Link>
              </li>
              <li>
                <Link to="/terms-and-conditions" className="hover:text-primary-400 flex items-center">
                  <FaAngleRight className='mr-2 text-primary-400 opacity-80' />
                  Terms and Conditions
                </Link>
              </li>
            </ul>
          </div>

          <div className="w-full md:w-1/5 mb-8 md:mb-0">
            <h2 className="text-xl font-semibold text-white mb-4">Features</h2>
            <ul className="space-y-2 text-gray-400">
              <li>
                <Link to="/add-invoice" className="hover:text-primary-400 flex items-center">
                  <FaAngleRight className='mr-2 text-primary-400 opacity-80' />
                  Invoice Extraction
                </Link>
              </li>
              <li>
                <Link to="/add-invoice" className="hover:text-primary-400 flex items-center">
                  <FaAngleRight className='mr-2 text-primary-400 opacity-80' />
                  Bill Extraction
                </Link>
              </li>
              <li>
                <Link to="/add-invoice" className="hover:text-primary-400 flex items-center">
                  <FaAngleRight className='mr-2 text-primary-400 opacity-80' />
                  Bank Statement Extraction
                </Link>
              </li>
              <li>
                <Link to="/add-invoice" className="hover:text-primary-400 flex items-center">
                  <FaAngleRight className='mr-2 text-primary-400 opacity-80' />
                   Logistics Extraction
                </Link>
              </li>
            </ul>
          </div>

          <div className="w-full md:w-1/5 mb-8 md:mb-0">
            <h2 className="text-xl font-semibold text-white mb-4">Connect With Us</h2>
            <div className="flex space-x-3">
              <a href="https://facebook.com" className="p-2 bg-gray-700 rounded-full hover:bg-primary-400 transition-colors duration-300">
                <svg className="w-4 h-4 fill-current text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                  <path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"></path>
                </svg>
              </a>
              <a href="https://twitter.com" className="p-2 bg-gray-700 rounded-full hover:bg-primary-400 transition-colors duration-300">
                <svg className="w-4 h-4 fill-current text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"></path>
                </svg>
              </a>
              <a href="https://instagram.com" className="p-2 bg-gray-700 rounded-full hover:bg-primary-400 transition-colors duration-300">
                <svg className="w-4 h-4 fill-current text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"></path>
                </svg>
              </a>
              <a href="https://github.com" className="p-2 bg-gray-700 rounded-full hover:bg-primary-400 transition-colors duration-300">
                <svg className="w-4 h-4 fill-current text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512">
                  <path d="M165.9 397.4c0 2-2.3 3.6-5.2 3.6-3.3.3-5.6-1.3-5.6-3.6 0-2 2.3-3.6 5.2-3.6 3-.3 5.6 1.3 5.6 3.6zm-31.1-4.5c-.7 2 1.3 4.3 4.3 4.9 2.6 1 5.6 0 6.2-2s-1.3-4.3-4.3-5.2c-2.6-.7-5.5.3-6.2 2.3zm44.2-1.7c-2.9.7-4.9 2.6-4.6 4.9.3 2 2.9 3.3 5.9 2.6 2.9-.7 4.9-2.6 4.6-4.6-.3-1.9-3-3.2-5.9-2.9zM244.8 8C106.1 8 0 113.3 0 252c0 110.9 69.8 205.8 169.5 239.2 12.8 2.3 17.3-5.6 17.3-12.1 0-6.2-.3-40.4-.3-61.4 0 0-70 15-84.7-29.8 0 0-11.4-29.1-27.8-36.6 0 0-22.9-15.7 1.6-15.4 0 0 24.9 2 38.6 25.8 21.9 38.6 58.6 27.5 72.9 20.9 2.3-16 8.8-27.1 16-33.7-55.9-6.2-112.3-14.3-112.3-110.5 0-27.5 7.6-41.3 23.6-58.9-2.6-6.5-11.1-33.3 2.6-67.9 20.9-6.5 69 27 69 27 20-5.6 41.5-8.5 62.8-8.5s42.8 2.9 62.8 8.5c0 0 48.1-33.6 69-27 13.7 34.7 5.2 61.4 2.6 67.9 16 17.7 25.8 31.5 25.8 58.9 0 96.5-58.9 104.2-114.8 110.5 9.2 7.9 17 22.9 17 46.4 0 33.7-.3 75.4-.3 83.6 0 6.5 4.6 14.4 17.3 12.1C428.2 457.8 496 362.9 496 252 496 113.3 383.5 8 244.8 8zM97.2 352.9c-1.3 1-1 3.3.7 5.2 1.6 1.6 3.9 2.3 5.2 1 1.3-1 1-3.3-.7-5.2-1.6-1.6-3.9-2.3-5.2-1zm-10.8-8.1c-.7 1.3.3 2.9 2.3 3.9 1.6 1 3.6.7 4.3-.7.7-1.3-.3-2.9-2.3-3.9-2-.6-3.6-.3-4.3.7zm32.4 35.6c-1.6 1.3-1 4.3 1.3 6.2 2.3 2.3 5.2 2.6 6.5 1 1.3-1.3.7-4.3-1.3-6.2-2.2-2.3-5.2-2.6-6.5-1zm-11.4-14.7c-1.6 1-1.6 3.6 0 5.9 1.6 2.3 4.3 3.3 5.6 2.3 1.6-1.3 1.6-3.9 0-6.2-1.4-2.3-4-3.3-5.6-2z"></path>
                </svg>
              </a>
            </div>
          </div>
        </div>

        <div className="mt-8 flex justify-center">
          <form className="w-full max-w-sm">
            <div className="flex items-center border-b border-b-2 border-gray-700 py-2">
              <input className="appearance-none bg-transparent border-none w-full text-gray-300 mr-3 py-1 px-2 leading-tight focus:outline-none" type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Subscribe to our newsletter" aria-label="Email"/>
              <button className="flex-shrink-0 rounded-full bg-gradient-to-r from-primary-500 to-primary-700 py-2 px-4 text-base text-white hover:from-primary-700 hover:to-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-600 transition-transform transform hover:scale-105 shadow-lg" type="button" onClick={handleSubscribe}>
                Subscribe
              </button>
            </div>
            {error && <p className="text-red-500 text-xs mt-2">{error}</p>}
          </form>
        </div>
      </div>
      <div className="bg-gray-900 py-4 text-gray-400">
        <div className="container mx-auto px-4 text-center">
          <p>&copy; {currentYear} GenAI Box. All Rights Reserved.</p>
          <p>Made with ❤️ by GenAI Box Team.</p>
        </div>
      </div>
      <SubscribeModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <h2 className="text-2xl font-bold text-gray-800">Thank You for Subscribing!</h2>
        <p className="mt-4 text-gray-600">We will keep you updated with the latest project and updates from DocumentAI.</p>
      </SubscribeModal>
    </footer>
  );
};

export default Footer;
