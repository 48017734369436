import React from 'react';

const HeroSection = () => {
  return (
    <>
      <section className="py-14 lg:py-24 text-center bg-gradient-to-r from-fuchsia-100 to-primary-100">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-4xl font-extrabold text-gray-900 mb-5 md:text-5xl">
            Transform Your Document Management with <span className="text-primary-600">Document.AI</span>
          </h1>
          <p className="text-base text-gray-700 mb-9 max-w-xl mx-auto">
            Effortlessly extract, structure, and analyze data from invoices, receipts, bank statements, and more with our AI-powered solution. Streamline your workflow, improve accuracy, and boost productivity.
          </p>
        </div>
      </section>
    </>
  );
};

export default HeroSection;
