import axios from 'axios';
import axiosInstance from '../../Common/axiosInstance';
import {
    INVOICE_TYPE_LOADING,
    INVOICE_TYPE_SUCCESS,
    INVOICE_TYPE_FAIL,

    UPLOAD_INVOICE_TYPE_LOADING,
    UPLOAD_INVOICE_TYPE_SUCCESS,
    UPLOAD_INVOICE_TYPE_FAIL, 

    INVOICE_TYPE_LIST_LOADING,
    INVOICE_TYPE_LIST_SUCCESS,
    INVOICE_TYPE_LIST_FAIL,

    UPLOAD_INVOICE_LOADING,
    UPLOAD_INVOICE_SUCCESS,
    UPLOAD_INVOICE_FAIL,

    INVOICE_LIST_LOADING,
    INVOICE_LIST_SUCCESS,
    INVOICE_LIST_FAIL,

    DASHBOARD_DATA_LOADING,
    DASHBOARD_DATA_SUCCESS,
    DASHBOARD_DATA_FAIL,
} from '../constants/invoiceConstatns';
import { toast } from 'react-toastify';
import { API_URL } from '../env';

export const getInvoiceType = () => async (dispatch) => {
    try {
        dispatch({ type: INVOICE_TYPE_LOADING });

        // const cachedData = localStorage.getItem('invoiceTypes');
        // if (cachedData) {
        //     const parsedData = JSON.parse(cachedData);
        //     const expirationTime = 24 * 60 * 60 * 1000; // 24 hours
        //     const currentTime = new Date().getTime();

        //     if (parsedData.timestamp && currentTime - parsedData.timestamp < expirationTime) {
        //         dispatch({
        //             type: INVOICE_TYPE_SUCCESS,
        //             payload: parsedData.data,
        //         });
        //         return parsedData.data;
        //     } else {
        //         localStorage.removeItem('invoiceTypes');
        //     }
        // }
        const { data } = await axiosInstance.get('/invoice/invoice-types/');
        localStorage.setItem('invoiceTypes', JSON.stringify({ data, timestamp: new Date().getTime() }));

        dispatch({
            type: INVOICE_TYPE_SUCCESS,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: INVOICE_TYPE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
};

export const UploadInvoiceType = (formData) => async (dispatch) => {
    try {
        dispatch({ type: UPLOAD_INVOICE_TYPE_LOADING });
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };
        const { data } = await axiosInstance.post('/invoice/user-invoice-types/', formData, config);

        dispatch({
            type: UPLOAD_INVOICE_TYPE_SUCCESS,
            payload: data,
        });

        return data;
    } catch (error) {
        dispatch({
            type: UPLOAD_INVOICE_TYPE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
        throw error; // Throw the error to handle it in the component
    }  
};

export const UpdateInvoiceType = (invoiceTypeId, updatedData) => async (dispatch) => {
    try { 

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };
        const { data } = await axiosInstance.post(`/invoice/update-user-invoice-types/${invoiceTypeId}/`, updatedData, config);
        
        // const response = await axiosInstance.put(`/invoice/update-user-invoice-types/${invoiceTypeId}/`, updatedData);

        return data;
    } catch (error) {
        toast.error('Error updating invoice type');
        throw error;
    }
};

export const fetchUserInvoiceType = () => async (dispatch) => {
    try {
        dispatch({ type: INVOICE_TYPE_LIST_LOADING });

        const cachedData = localStorage.getItem('invoiceTypesList');
        if (cachedData) {
            const parsedData = JSON.parse(cachedData);
            const expirationTime = 24 * 60 * 60 * 1000; // 24 hours
            const currentTime = new Date().getTime();

            if (parsedData.timestamp && currentTime - parsedData.timestamp < expirationTime) {
                dispatch({
                    type: INVOICE_TYPE_LIST_SUCCESS,
                    payload: parsedData.data,
                });
                return parsedData.data;
            } else {
                localStorage.removeItem('invoiceTypesList');
            }
        }

        const getUserDetail = localStorage.getItem('user');
        const userDetail = JSON.parse(getUserDetail);
        const { data } = await axiosInstance.get(`/invoice/user-invoice-types-list/${userDetail.id}/`);
        localStorage.setItem('invoiceTypesList', JSON.stringify({ data, timestamp: new Date().getTime() }));

        dispatch({
            type: INVOICE_TYPE_LIST_SUCCESS,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: INVOICE_TYPE_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
};

export const deleteInvoiceType = (invoiceTypeId) => async (dispatch) => {
    try {
        const response = await axiosInstance.delete(`/invoice/delete-user-invoice-types/${invoiceTypeId}/`);
        return response.data;
    } catch (error) {
        throw error;
    }
}; 

export const UploadInvoice = (formData) => async (dispatch) => {
    try {
        dispatch({ type: UPLOAD_INVOICE_LOADING });
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };
        const { data } = await axiosInstance.post('/invoice/invoice-extraction/', formData, config);

        dispatch({
            type: UPLOAD_INVOICE_SUCCESS,
            payload: data,
        });

        return data;
    } catch (error) {
        dispatch({
            type: UPLOAD_INVOICE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
        throw error; // Throw the error to handle it in the component
    }  
};

export const fetchInvoiceData = () => async (dispatch) => {
    try {
        dispatch({ type: INVOICE_LIST_LOADING });

        const cachedData = localStorage.getItem('invoiceList');
        if (cachedData) {
            const parsedData = JSON.parse(cachedData);
            const expirationTime = 24 * 60 * 60 * 1000; // 24 hours
            const currentTime = new Date().getTime();

            if (parsedData.timestamp && currentTime - parsedData.timestamp < expirationTime) {
                dispatch({
                    type: INVOICE_LIST_SUCCESS,
                    payload: parsedData.data,
                });
                return parsedData.data;
            } else {
                localStorage.removeItem('invoiceList');
            }
        }

        const getUserDetail = localStorage.getItem('user');
        const userDetail = JSON.parse(getUserDetail);
        const { data } = await axiosInstance.get(`/invoice/invoice-list/${userDetail.id}/`);
        localStorage.setItem('invoiceList', JSON.stringify({ data, timestamp: new Date().getTime() }));

        dispatch({
            type: INVOICE_LIST_SUCCESS,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: INVOICE_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
};

export const fetchDashboardData = () => async (dispatch) => {
    try {
        dispatch({ type: DASHBOARD_DATA_LOADING });

        // const cachedData = localStorage.getItem('dashboardData');
        // if (cachedData) {
        //     const parsedData = JSON.parse(cachedData);
        //     const expirationTime = 24 * 60 * 60 * 1000; // 24 hours
        //     const currentTime = new Date().getTime();

        //     if (parsedData.timestamp && currentTime - parsedData.timestamp < expirationTime) {
        //         dispatch({
        //             type: DASHBOARD_DATA_SUCCESS,
        //             payload: parsedData.data,
        //         });
        //         return parsedData.data;
        //     } else {
        //         localStorage.removeItem('dashboardData');
        //     }
        // }
        const getUserDetail = localStorage.getItem('user');
        const userDetail = JSON.parse(getUserDetail);
        const { data } = await axiosInstance.get(`/invoice/dashboard-data/${userDetail.id}/`);
        localStorage.setItem('dashboardData', JSON.stringify({ data, timestamp: new Date().getTime() }));

        dispatch({
            type: DASHBOARD_DATA_SUCCESS,
            payload: data,
        });
        return data;
    } catch (error) {
        dispatch({
            type: DASHBOARD_DATA_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        });
    }
};

export const deleteInvoice = (invoiceId) => async (dispatch) => {
    try {
        const response = await axiosInstance.delete(`/invoice/delete-invoice/${invoiceId}/`);
        return response.data;
    } catch (error) {
        throw error;
    }
};