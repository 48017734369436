import React from 'react';
import { FaUserCircle } from 'react-icons/fa';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import '../../style/Style.css';

const CustomerListCard = ({ customerData }) => {
  return (
    <div className="rounded-lg border border-stroke bg-white p-6 shadow-md dark:border-strokedark dark:bg-boxdark">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg font-semibold text-gray-800 dark:text-white">Latest Customers</h3>
        <Link to={'/scanned-invoices'} className="text-sm font-medium text-primary-600 hover:underline focus:outline-none">
          View More
        </Link>
      </div>
      <div className="space-y-4 max-h-96 overflow-y-scroll custom-scrollbar">
        {customerData && customerData.length > 0 ? (
          customerData.map((customer, index) => (
            <div
              key={index}
              className="flex items-center space-x-4 p-4 bg-gray-50 rounded-md shadow-sm dark:bg-gray-700 hover:bg-gray-100 mr-1 dark:hover:bg-gray-600 transition-colors duration-200"
            >
              <FaUserCircle className="text-gray-400 dark:text-gray-300" size={36} />
              <div className="flex-grow">
                <h4 className="text-sm font-medium text-gray-800 dark:text-gray-200">{customer.customer_name}</h4>
                <p className="text-xs text-gray-500 dark:text-gray-400">
                  {format(new Date(customer.created_at), 'dd-MM-yyyy')}
                </p>
              </div>
              <Link
                to={`/scanned-invoices`}
                className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
              >
                View
              </Link>
            </div>
          ))
        ) : (
          <div className="flex flex-col items-center justify-center h-32">
            <FaUserCircle className="text-gray-400 dark:text-gray-300" size={50} />
            <p className="mt-4 text-sm text-gray-500 dark:text-gray-400">No customers found</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomerListCard;
