import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import store from './redux/store';
import RouteList from './Common/RouteList';
import { AuthProvider } from './Common/AuthProvider';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Loader from './Loader';
import useScrollToTop from './useScrollToTop';

function App() {
  
  useScrollToTop();

  return (
    <GoogleOAuthProvider clientId="255910356358-lef7m6bhgaknak1u8h9u4bp2aforg4kg.apps.googleusercontent.com">
      <Provider store={store}>
        <AuthProvider>
          <RouteList />
          <Loader />
        </AuthProvider>
      </Provider>
    </GoogleOAuthProvider>
  );
}

export default App;
