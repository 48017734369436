import React from 'react';
import '../../../style/Style.css';

const InvoiceView = ({ updatedData }) => {
  const renderField = (label, value) => (
    <div className="flex justify-between items-start my-2 p-2 bg-white">
      <span className="font-semibold text-gray-700 mr-2">{label}</span>
      <span className="text-gray-600">{value}</span>
    </div>
  );

  const renderArray = (label, array) => (
    <div className="bg-white rounded-md p-6 mb-6">
      <h3 className="text-xl font-bold text-primary-600 mb-4">{label}</h3>
      {array.map((item, index) => (
        <div key={index} className="mb-4 border-b pb-4 last:border-b-0">
          {typeof item === 'object' ? (
            <>
              <h4 className="text-lg font-semibold text-gray-800 mb-2">Item {index + 1}</h4>
              <div className="pl-4">
                {Object.entries(item).map(([subKey, subValue]) => renderField(subKey.replace(/_/g, ' '), subValue))}
              </div>
            </>
          ) : (
            <div className="pl-4">
              {renderField(`Note ${index + 1}`, item)}
            </div>
          )}
        </div>
      ))}
    </div>
  );

  const renderSection = (label, fields) => (
    <div className="mb-6">
      <h3 className="text-xl font-bold text-primary-600 mb-4">{label}</h3>
      {fields}
    </div>
  );

  const fields = Object.entries(updatedData).reduce((acc, [key, value]) => {
    if (Array.isArray(value)) {
      acc.arrays.push(renderArray(key.replace(/_/g, ' '), value));
    } else if (typeof value === 'object' && value !== null) {
      acc.sections.push(renderSection(key.replace(/_/g, ' '), Object.entries(value).map(([subKey, subValue]) => renderField(subKey.replace(/_/g, ' '), subValue))));
    } else {
      acc.general.push(renderField(key.replace(/_/g, ' '), value));
    }
    return acc;
  }, { general: [], sections: [], arrays: [] });

  return (
    <div className="max-w-4xl mx-auto my-10 p-6 mt-5 bg-primary-50 shadow-md rounded-lg">
      <div>
        {fields.general.length > 0 && renderSection('General Information', fields.general)}
        {fields.sections}
        {fields.arrays}
      </div>
    </div>
  );
};

export default InvoiceView;
