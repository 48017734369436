import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { UploadInvoice, fetchUserInvoiceType } from '../../../redux/actions/invoiceAction';
import 'react-toastify/dist/ReactToastify.css';
import ModalLoader from '../../../Components/ModalLoader';
import Container from '../../../Components/Container';
import ContentHeader from '../../../Components/ContentHeader';
import Card from '../../../Components/Card';
import SelectorDropdown from '../../../Components/SelectorDropdown';
import MultiFileSelector from '../../../Components/MultiFileSelector';
import Button from '../../../Components/Button';

const NewScan = () => {
    const dispatch = useDispatch();
    const [invoiceType, setInvoiceType] = useState([]);
    const [filePreviews, setFilePreviews] = useState([]);
    const [invoiceData, setInvoiceData] = useState({});
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);

    const navigate = useNavigate();

    useEffect(() => {
        dispatch(fetchUserInvoiceType()).then((res) => {
            setInvoiceType(res);
        });
    }, [dispatch]);

    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);
        if (files.length > 0) {
            // Update validTypes to include both application/pdf and application/x-pdf
            const validTypes = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf', 'application/x-pdf'];
            const newPreviews = [];
            let hasError = false;
    
            files.forEach((file) => {
                if (!validTypes.includes(file.type)) {
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        invoiceFile: 'Invalid file type. Only jpg, jpeg, png, and pdf are allowed.',
                    }));
                    hasError = true;
                    return;
                } else if (file.size > 2 * 1024 * 1024) {
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        invoiceFile: 'File size must be less than 2MB.',
                    }));
                    hasError = true;
                    return;
                } else {
                    newPreviews.push({
                        url: URL.createObjectURL(file),
                        name: file.name,
                        type: file.type,
                    });
                }
            });
    
            if (!hasError) {
                setErrors((prevErrors) => ({ ...prevErrors, invoiceFile: '' }));
                setInvoiceData({ ...invoiceData, invoiceFiles: files });
                setFilePreviews(newPreviews);
            } else {
                event.target.value = null;
            }
        }
    };
    

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setInvoiceData({ ...invoiceData, [id]: value });
    };

    const simulateProgress = () => {
        const increment = () => {
            setProgress((prevProgress) => {
                const nextProgress = prevProgress + 1;
                return nextProgress >= 95 ? 100 : nextProgress;
            });
        };
        const interval = setInterval(increment, 200);
        return () => clearInterval(interval);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        // Check if files are present
        if (!invoiceData.invoiceFiles || invoiceData.invoiceFiles.length === 0) {
            setErrors((prevErrors) => ({ ...prevErrors, invoiceFile: 'Files are required.' }));
            return;
        }
    
        // Perform any other validation here if needed
        if (!invoiceData.selectInvoiceType) {
            setErrors((prevErrors) => ({ ...prevErrors, selectInvoiceType: 'Template is required.' }));
            return;
        }
    
        setLoading(true);
        simulateProgress();
    
        const formData = new FormData();
        formData.append('user_invoice_type_id', invoiceData.selectInvoiceType);
    
        invoiceData.invoiceFiles.forEach((file, index) => {
            formData.append(`invoice_file_${index}`, file);
        }); 
    
        try {
            const res = await dispatch(UploadInvoice(formData));
    
            if (res) {
                setProgress(100);
                toast.success('Document data extracted successfully');
                localStorage.removeItem('invoiceList');
                localStorage.removeItem('dashboardData');
                setTimeout(() => {
                    setLoading(false);
                    navigate('/scanned-invoices');
                }, 3000);
            }
        } catch (error) {
            console.error('Upload error:', error);
            setLoading(false);
        }
    };
    

    return (
        <Container>
            <ContentHeader heading="Extract Document" style="text-center pt-4" subheading="Scan and extract data from your document quickly and accurately" />
            <ToastContainer />
            <Card className="w-full max-w-4xl mt-8">
                <form onSubmit={handleSubmit} className="space-y-6">
                    <SelectorDropdown
                        id="selectInvoiceType"
                        value={invoiceData.selectInvoiceType}
                        onChange={handleInputChange}
                        options={invoiceType}
                        error={errors.selectInvoiceType}
                        placeholder={'Select Template'}
                        required
                    />
                    <div className="relative">
                        <MultiFileSelector
                            id="reference_image"
                            onChange={handleFileChange}
                            error={errors.invoiceFile}
                            previews={filePreviews}
                        />
                    </div>
                    <div className="flex justify-center">
                        <Button type="submit">Upload Invoice</Button>
                    </div>
                </form>
            </Card>
            {loading && <ModalLoader progress={progress} message="Processing your invoice..." />}
            <ToastContainer />
        </Container>
    );
};

export default NewScan;
