import React from 'react';
import HomePageLayout from './Home/Homelayout';
import FeaturesSection from './Home/Featuresection';
import TestimonialsSection from './Home/Testimonialsection';
import CallToActionSection from './Home/Calltoactionsection';
import HomePageHero from './Home/HomePageHero';

const Home = () => {
    return (
        <div> 
            <HomePageHero />
            <HomePageLayout />
            <FeaturesSection />
            <TestimonialsSection />
            <CallToActionSection />
        </div>
    );
};

export default Home;
