import React from 'react';
import HeroSection from './About/HeroSection';
import TeamSection from './About/TeamSection'; 
import { AboutUsSection } from './About/AboutusSection';
import Divider from './About/Divider'; 

const About = () => {
  return (
    <> 
      <HeroSection />
      <AboutUsSection />
      <Divider />
      {/* <TeamSection /> */}
    </>
  );
};

export default About;
