import React, { useState } from 'react';
import ReactPaginate from 'react-paginate';
import { FaUserEdit, FaTrash, FaSearch, FaUserPlus } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const teamMembers = [
    { name: 'John Doe', email: 'john@example.com', role: 'Manager' },
    { name: 'Jane Smith', email: 'jane@example.com', role: 'Manager' },
    { name: 'Alice Johnson', email: 'alice@example.com', role: 'Manager' },
    { name: 'Bob Brown', email: 'bob@example.com', role: 'Manager' },
    { name: 'Carol White', email: 'carol@example.com', role: 'Manager' },
    // Add more team members as needed for testing pagination
];

const TeamList = () => {
    const [currentPage, setCurrentPage] = useState(0);
    const [filter, setFilter] = useState('');

    const itemsPerPage = 2;

    const handlePageClick = (data) => {
        setCurrentPage(data.selected);
    };

    const filteredTeamMembers = teamMembers.filter((member) =>
        member.name.toLowerCase().includes(filter.toLowerCase()) ||
        member.email.toLowerCase().includes(filter.toLowerCase()) ||
        member.role.toLowerCase().includes(filter.toLowerCase())
    );

    const offset = currentPage * itemsPerPage;
    const currentTeamMembers = filteredTeamMembers.slice(offset, offset + itemsPerPage);

    return (
        <div>
            <div className="grid grid-col-1 mt-6 mb-6 ">
                <h1 className="text-4xl font-bold text-gray-500 dark:text-white md:text-3xl">Team Overview</h1>
                <p className="text-gray-500 dark:text-gray-300">
                    Manage team, view members, and add new ones!
                </p>
            </div>
            <div className="p-6 bg-white rounded-lg shadow-md">
                <div className="flex justify-between items-center mb-6">
                    <div className="relative w-1/3">
                        <FaSearch className="absolute top-2.5 left-3 text-gray-500" />
                        <input
                            type="text"
                            placeholder="Filter team members"
                            className="w-full p-2 pl-10 border border-gray-300 rounded-md focus:ring focus:ring-primary-200 focus:border-primary-500"
                            value={filter}
                            onChange={(e) => setFilter(e.target.value)}
                        />
                    </div>
                    <Link to="/add-member" className="flex items-center px-8 py-2 text-white bg-primary-600 rounded-md shadow-md hover:from-primary-600 hover:to-primary-600 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">
                        <FaUserPlus className="mr-2" />
                        Add Member
                    </Link>
                </div>
                <div className="overflow-x-auto">
                    <table className="min-w-full bg-white shadow-md rounded-lg">
                        <thead className="bg-gradient-to-r from-primary-100 to-primary-100">
                            <tr>
                                <th className="py-3 px-5 text-left text-sm font-semibold text-gray-700">Name</th>
                                <th className="py-3 px-5 text-left text-sm font-semibold text-gray-700">Email</th>
                                <th className="py-3 px-5 text-left text-sm font-semibold text-gray-700">Role</th>
                                <th className="py-3 px-5 text-left text-sm font-semibold text-gray-700">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentTeamMembers.length > 0 ? (
                                currentTeamMembers.map((member, index) => (
                                    <tr key={index} className="border-b hover:bg-gray-50">
                                        <td className="py-3 px-5 text-sm text-gray-800">{member.name}</td>
                                        <td className="py-3 px-5 text-sm text-gray-800">{member.email}</td>
                                        <td className="py-3 px-5 text-sm text-gray-800">{member.role}</td>
                                        <td className="py-3 px-5 text-sm">
                                            <Link to="/edit-member" className="px-4 py-1 text-primary-600 border border-primary-600 bg-white hover:bg-primary-600 hover:text-white rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">Edit</Link>
                                            <button className="ml-2 px-4 py-1 text-red-600 border border-red-600 bg-white hover:bg-red-600 hover:text-white rounded-md focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2">Delete</button>
                                        </td>
                                    </tr>
                                ))) : (
                                <tr>
                                    <td colSpan="4" className="py-10 text-center">
                                        <div className="flex flex-col items-center">
                                            <FaUserEdit className="mb-3 text-6xl text-gray-400" />
                                            <p className="text-lg font-medium text-gray-600">No Team Members Found</p>
                                            <p className="text-sm text-gray-500">Try adding new members!</p>
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                <div className="mt-6">
                    {currentTeamMembers.length > 0 && (
                        <ReactPaginate
                            previousLabel={'Previous'}
                            nextLabel={'Next'}
                            breakLabel={'...'}
                            pageCount={Math.ceil(filteredTeamMembers.length / itemsPerPage)}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageClick}
                            containerClassName={'flex justify-center space-x-2'}
                            pageClassName={'px-3 py-1 border border-gray-300 rounded-md hover:bg-gray-100'}
                            previousClassName={'px-3 py-1 border border-gray-300 rounded-md hover:bg-gray-100'}
                            nextClassName={'px-3 py-1 border border-gray-300 rounded-md hover:bg-gray-100'}
                            breakClassName={'px-3 py-1 border border-gray-300 rounded-md hover:bg-gray-100'}
                            activeClassName={'bg-primary-600 text-white'}
                        />)}
                </div>
            </div>
        </div>
    );
};

export default TeamList;
