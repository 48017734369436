import axios from 'axios';
import {jwtDecode} from 'jwt-decode';
import { API_URL } from '../redux/env';
import { logout } from '../redux/actions/authActions';

let logoutFn = null;

export const setLogoutFunction = (logoutFunction) => {
  logoutFn = logoutFunction;
};

const axiosInstance = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

const setContentType = (config, contentType) => {
    config.headers['Content-Type'] = contentType;
    return config;
};

axiosInstance.interceptors.request.use(async (config) => {
    const token = localStorage.getItem('token');
    const refreshToken = localStorage.getItem('refreshToken');

    if (token) {
        const decodedToken = jwtDecode(token);

        // Check if token is expired
        if (decodedToken.exp * 1000 < Date.now()) {
            if (refreshToken) {
                try {
                    const response = await axios.post(`${API_URL}/auth/refresh/`, { refresh: refreshToken });

                    const newToken = response.data.access;
                    const newRefreshToken = response.data.refresh;

                    localStorage.setItem('token', newToken);
                    localStorage.setItem('refreshToken', newRefreshToken);

                    config.headers['Authorization'] = `Bearer ${newToken}`;
                } catch (error) {
                    // Optionally, log out the user if the refresh fails
                    if (logoutFn) logoutFn();
                }
            } else {
                // No refresh token available, log out the user
                if (logoutFn) logoutFn();
            }
        } else {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
    }

    return config;
}, (error) => {
    return Promise.reject(error);
});

axiosInstance.interceptors.response.use(
    
    (response) => {
        return response;
    },
    async (error) => {
        const originalRequest = error.config;

        if (error.response.status === 401 || error.response.status === 403 && !originalRequest._retry) {
            originalRequest._retry = true;
            const refreshToken = localStorage.getItem('refreshToken');

            if (refreshToken) {
                try {
                    const response = await axios.post(`${API_URL}/auth/refresh/`, { refresh: refreshToken });
                    
                    const newToken = response.data.access;
                    const newRefreshToken = response.data.refresh;

                    localStorage.setItem('token', newToken);
                    localStorage.setItem('refreshToken', newRefreshToken);

                    axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
                    originalRequest.headers['Authorization'] = `Bearer ${newToken}`;

                    return axiosInstance(originalRequest);
                } catch (error) {
                    if (logoutFn) logoutFn();
                }
            } else {
                if (logoutFn) logoutFn();
            }
        }

        return Promise.reject(error);
    }
);

// export const axiosInstanceWithMultipart = () => {
//     const config = axiosInstance.defaults;
//     return setContentType(config, 'multipart/form-data');
// };

export const axiosInstanceWithJson = () => {
    const config = axiosInstance.defaults;
    return setContentType(config, 'application/json');
};

export default axiosInstance;
