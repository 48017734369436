import React from 'react';

const PrivacyPolicy = () => {
    return (
        <section className="bg-white text-gray-900 py-8">
            <div className="container mx-auto px-4 sm:px-6 lg:px-8">
                <div className="max-w-3xl mx-auto">
                    <h1 className="text-3xl font-bold mb-8">Privacy Policy</h1>
                    <p className="mb-4">
                        At DocumentAI, we are committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our Document Extractor service. Please read this policy carefully to understand our views and practices regarding your personal data and how we will treat it.
                    </p>

                    <h2 className="text-2xl font-semibold mb-4">1. Information We Collect</h2>
                    <p className="mb-4">
                        We collect several types of information from and about users of our service, including:
                    </p>
                    <ul className="list-disc list-inside mb-4">
                        <li>
                            <strong>Personal Information:</strong> When you register for an account, we may collect personal details such as your name, email address, phone number, and billing information.
                        </li>
                        <li>
                            <strong>Invoice Data:</strong> We collect and process the data contained in the invoices you upload to our service for extraction and analysis.
                        </li>
                        <li>
                            <strong>Usage Data:</strong> We collect information about your interactions with our service, such as the features you use and the time spent on our platform.
                        </li>
                        <li>
                            <strong>Technical Data:</strong> We may collect information about your device and internet connection, including your IP address, browser type, and operating system.
                        </li>
                    </ul>

                    <h2 className="text-2xl font-semibold mb-4">2. How We Use Your Information</h2>
                    <p className="mb-4">
                        We use the information we collect for various purposes, including:
                    </p>
                    <ul className="list-disc list-inside mb-4">
                        <li>To provide, operate, and maintain our service</li>
                        <li>To improve, personalize, and expand our service</li>
                        <li>To understand and analyze how you use our service</li>
                        <li>To develop new products, services, features, and functionality</li>
                        <li>To process your transactions and manage your orders</li>
                        <li>To communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the service, and for marketing and promotional purposes</li>
                        <li>To send you emails</li>
                        <li>To find and prevent fraud</li>
                    </ul>

                    <h2 className="text-2xl font-semibold mb-4">3. How We Share Your Information</h2>
                    <p className="mb-4">
                        We may share the information we collect in various ways, including:
                    </p>
                    <ul className="list-disc list-inside mb-4">
                        <li>
                            <strong>With Service Providers:</strong> We may share your information with third-party service providers to perform tasks on our behalf and to assist us in providing the service.
                        </li>
                        <li>
                            <strong>For Business Transfers:</strong> We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.
                        </li>
                        <li>
                            <strong>With Affiliates:</strong> We may share your information with our affiliates, in which case we will require those affiliates to honor this Privacy Policy.
                        </li>
                        <li>
                            <strong>For Legal Requirements:</strong> We may disclose your information if required to do so by law or in response to valid requests by public authorities.
                        </li>
                    </ul>

                    <h2 className="text-2xl font-semibold mb-4">4. Data Security</h2>
                    <p className="mb-4">
                        We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse.
                    </p>

                    <h2 className="text-2xl font-semibold mb-4">5. Your Data Protection Rights</h2>
                    <p className="mb-4">
                        Depending on your location, you may have the following rights regarding your personal data:
                    </p>
                    <ul className="list-disc list-inside mb-4">
                        <li>The right to access – You have the right to request copies of your personal data.</li>
                        <li>The right to rectification – You have the right to request that we correct any information you believe is inaccurate or complete information you believe is incomplete.</li>
                        <li>The right to erasure – You have the right to request that we erase your personal data, under certain conditions.</li>
                        <li>The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.</li>
                        <li>The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.</li>
                        <li>The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.</li>
                    </ul>

                    <h2 className="text-2xl font-semibold mb-4">6. Third-Party Services</h2>
                    <p className="mb-4">
                        Our service may contain links to third-party websites, products, or services. We do not control these third-party websites, and this Privacy Policy does not apply to them. We encourage you to review the privacy policies of any third-party websites you visit.
                    </p>

                    <h2 className="text-2xl font-semibold mb-4">7. Children's Privacy</h2>
                    <p className="mb-4">
                        Our service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from children under 13. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to take the necessary actions.
                    </p>

                </div>

            </div>
        </section>)
}

export default PrivacyPolicy

