import React from 'react';
import ServiceHero from './Service/ServiceHero';
import ServiceSection from './Service/ServiceSection';
import FAQSection from './Service/FaqSection';

const Services = () => {
  return (
    <>
      <ServiceHero />
      <ServiceSection />
      <FAQSection />
    </>
  );
};

export default Services;
