import React from 'react';
import ContactUs from './Contact/ContactUs';
import ContactHero from './Contact/ContactHero';
import ContactMap from './Contact/ContactMap';

const Contact = () => {
  return (
    <>
      <ContactHero />
      <ContactUs />
      <ContactMap />
    </>
  );
};

export default Contact;
