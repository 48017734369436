import React from 'react';

const InputBox = ({ id, type, placeholder, value, onChange, error, ...props }) => {
    return (
        <div className="my-4">
            <input
                type={type}
                id={id}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                className={`w-full px-4 py-2 border text-gray-500 font-normal rounded-md focus:outline-none focus:ring-2 focus:ring-primary-400 ${error ? 'border-red-500' : 'border-gray-300'}`}
                {...props}
            />
            {error && <p className="text-red-500 text-xs mt-2">{error}</p>}
        </div>
    );
};

export default InputBox;
