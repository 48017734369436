import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { register, googleAuth } from '../../../redux/actions/authActions';
import { Link, useNavigate } from 'react-router-dom';
import { useGoogleLogin, GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";
import { useAuth } from '../../../Common/AuthProvider';

const SignupPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [errors, setErrors] = useState({});

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const authState = useSelector((state) => state.auth);
    const { loading, error } = authState;
    const { login, isAuthenticated } = useAuth();

    useEffect(() => {
        if (isAuthenticated) {
            navigate(-1);
        }
    }, [isAuthenticated]);

    const validate = () => {
        const errors = {};
        if (!firstName) {
            errors.firstName = 'First name is required.';
        } else if (firstName.length > 200) {
            errors.firstName = 'First name must be less than 200 characters.';
        }
        if (!lastName) {
            errors.lastName = 'Last name is required.';
        } else if (lastName.length > 200) {
            errors.lastName = 'Last name must be less than 200 characters.';
        }
        if (!email) {
            errors.email = 'Email is required.';
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            errors.email = 'Email address is invalid.';
        } else if (email.length > 200) {
            errors.email = 'Email must be less than 200 characters.';
        }
        if (!password) {
            errors.password = 'Password is required.';
        } else if (password.length < 6) {
            errors.password = 'Password must be at least 6 characters.';
        } else if (password.length > 200) {
            errors.password = 'Password must be less than 200 characters.';
        }
        return errors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validate();
        setErrors(validationErrors);
        if (Object.keys(validationErrors).length === 0) {
            const userData = {
                email,
                password,
                first_name: firstName,
                last_name: lastName,
            };
            try {
                const response = await dispatch(register(userData));
                if (response) {
                    navigate('/email-verification', { state: { email: email } });
                }
            } catch (error) {
                setErrors({ general: error.response.data });
                if (error.response && error.response.data && error.response.data.email) {
                    setErrors({ general: error.response.data.email[0] });
                } else {
                    setErrors({ general: 'An error occurred. Please try again.' });
                }
            }
        }
    };

    const handleGoogleSuccess = async (response) => {
        const token = response.credential;
        const decoded = jwtDecode(token);

        const userData = {
            email: decoded.email,
            first_name: decoded.given_name,
            last_name: decoded.family_name,
            id_token: token,
        };
        await dispatch(googleAuth(userData)).then((res) => {
            if (res && res.access) {
                login(res.access);
                localStorage.setItem('user', JSON.stringify(res.user));
            }
        });
    };

    const handleGoogleFailure = (error) => {
        console.error('Google Login Failed:', error);
    };

    return (
        <div className="min-h-screen bg-gray-100 text-gray-900 flex justify-center items-center">
            <div className="max-w-screen-xl m-10 flex justify-center flex-1">
                <div className="lg:w-1/2 xl:w-5/12 p-6 sm:p-12 bg-white shadow-lg rounded-lg">
                    <div className="mt-12 flex flex-col items-center">
                        <h1 className="text-3xl font-extrabold text-gray-900">
                            Create Account
                        </h1>
                        <p className="text-gray-600 mt-2">
                            Join us today! It takes only a few steps
                        </p>
                        <div className="w-full flex-1 mt-8">
                            <div className="flex flex-col items-center">
                                <div className="flex flex-col items-center">
                                    <GoogleLogin
                                        onSuccess={handleGoogleSuccess}
                                        onFailure={handleGoogleFailure}
                                    />
                                </div>
                            </div>

                            <div className="my-12 border-b text-center">
                                <div className="leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform translate-y-1/2">
                                    Or sign up with email
                                </div>
                            </div>

                            <div className="mx-auto max-w-xs">
                                <form onSubmit={handleSubmit}>
                                    <div className="mb-4">
                                        <input
                                            className={`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border ${errors.firstName ? 'border-red-500' : 'border-gray-200'} placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white`}
                                            type="text"
                                            placeholder="First Name"
                                            value={firstName}
                                            onChange={(e) => setFirstName(e.target.value)}
                                        />
                                        {errors.firstName && <p className="text-red-500 text-xs mt-2">{errors.firstName}</p>}
                                    </div>
                                    <div className="mb-4">
                                        <input
                                            className={`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border ${errors.lastName ? 'border-red-500' : 'border-gray-200'} placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white`}
                                            type="text"
                                            placeholder="Last Name"
                                            value={lastName}
                                            onChange={(e) => setLastName(e.target.value)}
                                        />
                                        {errors.lastName && <p className="text-red-500 text-xs mt-2">{errors.lastName}</p>}
                                    </div>
                                    <div className="mb-4">
                                        <input
                                            className={`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border ${errors.email ? 'border-red-500' : 'border-gray-200'} placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white`}
                                            type="email"
                                            placeholder="Email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                        {errors.email && <p className="text-red-500 text-xs mt-2">{errors.email}</p>}
                                    </div>
                                    <div className="mb-4">
                                        <input
                                            className={`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border ${errors.password ? 'border-red-500' : 'border-gray-200'} placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white`}
                                            type="password"
                                            placeholder="Password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                        {errors.password && <p className="text-red-500 text-xs mt-2">{errors.password}</p>}
                                    </div>
                                    <button
                                        type="submit"
                                        className="mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-700 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none"
                                    >
                                        <svg
                                            className="w-6 h-6 -ml-2"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        >
                                            <path d="M16 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2" />
                                            <circle cx="8.5" cy="7" r="4" />
                                            <path d="M20 8v6M23 11h-6" />
                                        </svg>
                                        <span className="ml-3">Sign Up</span>
                                    </button>
                                </form>
                                {loading && <p>Loading...</p>}
                                {errors.general && <p className="text-red-500 mt-3">{errors.general}</p>}
                            </div>
                            <div className="mt-6 text-center">
                                <span className="text-sm font-medium text-gray-600">
                                    Already have an account? 
                                </span>
                                <Link to="/signin" className="ml-2 text-sm font-medium text-primary-500 hover:text-primary-700">
                                    Sign in
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex-1 bg-primary-100 text-center hidden lg:flex">
                    <div
                        className="m-12 xl:m-16 w-full bg-contain bg-center bg-no-repeat"
                        style={{ backgroundImage: 'url("https://storage.googleapis.com/devitary-image-host.appspot.com/15848031292911696601-undraw_designer_life_w96d.svg")' }}
                    ></div>
                </div>
            </div>
        </div>
    );
};

export default SignupPage;
