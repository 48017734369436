import React from 'react';

const FileSelector = ({ id, onChange, error, preview, ...props }) => {
    return (
        <div className="mt-6">
            <label
                htmlFor={id}
                className="flex flex-col items-center justify-center w-full h-40 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-white hover:bg-gray-50 transition-all duration-300">
                <div className="flex flex-col items-center justify-center pt-5">
                    <svg
                        className="w-10 h-10 mb-4 text-primary-500"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 30 16"
                    >
                        <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                        />
                    </svg>
                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                        <span className="font-semibold">Click to upload Reference File</span> or drag and drop
                    </p>
                </div>

                <input
                    id={id}
                    type="file"
                    className="hidden"
                    onChange={onChange}
                    {...props}
                />
            </label>
            {error && <p className="text-red-500 text-xs mt-2">{error}</p>}
            {preview && (
                <div className="mt-4">
                    {preview.type.startsWith('image/') ? (
                        <img src={preview.url} alt="File Preview" className="w-full h-32 object-contain" />
                    ) : (
                        <a href={preview.url} target="_blank" rel="noopener noreferrer" className="text-primary-500 underline">
                            {preview.name}
                        </a>
                    )}
                </div>
            )}
        </div>
    );
};

export default FileSelector;
