import React, { useState } from 'react';
import { IoArrowBack } from "react-icons/io5";
import InvoiceSetup from './InvoiceSetup';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { UpdateInvoiceType } from '../../../redux/actions/invoiceAction';
import { toast, ToastContainer } from 'react-toastify';
import Button from '../../../Components/Button';
import Container from '../../../Components/Container';
import ContentHeader from '../../../Components/ContentHeader';
import Card from '../../../Components/Card';

const EditInvoice = () => {
    const [updatedData, setUpdatedData] = useState(null);

    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { invoiceId, extractedData, invoiceFile } = location.state;

    const handleBackButtonClick = () => {
        window.history.back();
    };

    const removeValues = (obj) => {
        const result = {};
        Object.keys(obj).forEach(key => {
            if (Array.isArray(obj[key])) {
                result[key] = obj[key].map(item => (typeof item === 'object' && item !== null) ? removeValues(item) : '');
            } else if (typeof obj[key] === 'object' && obj[key] !== null) {
                result[key] = removeValues(obj[key]);
            } else {
                result[key] = '';
            }
        });
        return JSON.stringify(result);
      };

    const submitChanges = async () => {
        try { 
            const setupdatedData = {
                extracted_text: JSON.stringify(updatedData),
                extracted_label: removeValues(updatedData),
              };
            const res = await dispatch(UpdateInvoiceType(invoiceId, setupdatedData));
            if (res) {
                localStorage.setItem('tostLocal', 'Invoice type updated successfully!');
                localStorage.removeItem('invoiceTypesList');
                toast.success('Invoice type updated successfully');

                setTimeout(() => {
                    navigate('/invoices');
                }, 3000);
            }
        } catch (error) {
            console.error('Submit changes error:', error);
        }
    };

    return (
        <Container>
            <ContentHeader heading="Invoice Template" style="text-center" subheading="Update invoice template columns" />
            <ToastContainer />
            <div className='col-span-1 ml-6 -mt-16 mb-4 pt-2'>
                <Button onClick={handleBackButtonClick} className="flex text-gray-600 text-sm border border-gray-300 bg-white hover:bg-gray-600 hover:text-white"><IoArrowBack className='mt-1 mr-1' /> Back</Button>
            </div>
            <Card className="w-full max-w-5xl">
                <div className="grid grid-cols-10">

                    <div className='col-span-12  pb-4'>
                        <InvoiceSetup extractedData={extractedData} invoiceFile={invoiceFile} setUpdatedData={setUpdatedData} />
                    </div>
                </div>
                <div className="flex justify-center pb-6">
                    <Button
                        onClick={submitChanges}
                    >Submit Changes</Button>
                </div>
            </Card>
        </Container>
    );
};

export default EditInvoice;
