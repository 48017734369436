import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const location = useLocation();

  const activeLink = (pathname) => {
    return location.pathname === pathname ? 'text-primary-600' : 'text-gray-700';
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  const userActive = localStorage.getItem('token');

  return (
    <header className="bg-white shadow-md py-3">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <nav className="relative z-50 flex justify-between items-center">
          <div className="flex items-center md:gap-x-12">
            <Link to="/" className="flex items-center space-x-3 rtl:space-x-reverse">
              <img src="/images/logo.png" className="hidden md:inline h-12" alt="Logo" />
              <span className="self-center text-2xl font-extrabold text-gray-900">
              Document.AI
              </span>
            </Link>
          </div>
          <div className="hidden md:flex md:gap-x-6 flex-1 justify-center">
            <Link
              className={`inline-block font-semibold rounded-lg px-2 py-1 text-md ${activeLink('/about')} hover:bg-gray-100 hover:text-gray-900`}
              to="/about"
            >
              Who We Are
            </Link>
            <Link
              className={`inline-block font-semibold rounded-lg px-2 py-1 text-md ${activeLink('/service')} hover:bg-gray-100 hover:text-gray-900`}
              to="/service"
            >
              Our Expertise
            </Link>
            <Link
              className={`inline-block font-semibold rounded-lg px-2 py-1 text-md ${activeLink('/contact')} hover:bg-gray-100 hover:text-gray-900`}
              to="/contact"
            >
              Connect with Us
            </Link>
          </div>
          <div className="flex items-center gap-x-5 md:gap-x-8">
            {!userActive ? (
              <>
                <div className="hidden md:block">
                  <Link
                    className={`inline-block font-semibold rounded-lg px-2 py-1 ${activeLink('/signin')} text-md hover:bg-gray-100 hover:text-gray-900`}
                    to="/signin"
                  >
                    Sign in
                  </Link>
                </div>
                <Link
                  className="hidden lg:inline group inline-flex items-center justify-center rounded-full py-2 px-4 text-md font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-gradient-to-r from-primary-600 to-primary-500 text-white hover:from-primary-700 hover:to-primary-600 active:bg-primary-800 active:text-primary-100 focus-visible:outline-primary-600"
                  to="/signup"
                >
                  <span>
                    Get started <span className="hidden lg:inline">today</span>
                  </span>
                </Link>
              </>
            ) : (
              <Link
                className="group inline-flex -mr-4 rounded-full py-2 px-3 md:px-4 text-md font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-gradient-to-r from-primary-600 to-primary-500 text-white hover:from-primary-700 hover:to-primary-600 active:bg-primary-800 active:text-primary-100 focus-visible:outline-primary-600"
                to="/dashboard"
              >
                <span>Dashboard</span>
              </Link>
            )}
            <div className="-mr-1 md:hidden">
              <button
                onClick={toggleMobileMenu}
                className="relative z-10 flex h-8 w-8 items-center justify-center"
              >
                <svg
                  aria-hidden="true"
                  className="h-3.5 w-3.5 overflow-visible stroke-gray-700"
                  fill="none"
                  strokeWidth="2"
                  strokeLinecap="round"
                >
                  <path
                    d={
                      isMobileMenuOpen
                        ? 'M2 2L12 12M12 2L2 12'
                        : 'M0 1H14M0 7H14M0 13H14'
                    }
                    className="origin-center transition"
                  />
                </svg>
              </button>
            </div>
          </div>
        </nav>
        {isMobileMenuOpen && (
          <div className="md:hidden mt-4 bg-white shadow-md rounded-lg p-4 space-y-2">
            <Link
              className="block font-semibold rounded-lg px-2 py-1 text-md text-gray-700 hover:bg-gray-100 hover:text-gray-900"
              to="/about"
              onClick={closeMobileMenu}
            >
              Who We Are
            </Link>
            <Link
              className="block font-semibold rounded-lg px-2 py-1 text-md text-gray-700 hover:bg-gray-100 hover:text-gray-900"
              to="/service"
              onClick={closeMobileMenu}
            >
              Our Expertise
            </Link>
            <Link
              className="block font-semibold rounded-lg px-2 py-1 text-md text-gray-700 hover:bg-gray-100 hover:text-gray-900"
              to="/contact"
              onClick={closeMobileMenu}
            >
              Connect with Us
            </Link>
            {!userActive ? (
              <Link
                className="block font-semibold rounded-lg px-2 py-1 text-md text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                to="/signin"
                onClick={closeMobileMenu}
              >
                Sign in
              </Link>
            ) : null}
          </div>
        )}
      </div>
    </header>
  );
};

export default Navbar;
