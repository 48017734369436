import React from 'react';

const testimonials = [
  {
    name: "Alex Johnson",
    role: "Mobile Developer at TechCorp",
    image: "https://randomuser.me/api/portraits/men/45.jpg",
    text: "DocumentAI has drastically improved our invoice processing. The automated features are a game-changer for our team, saving us countless hours each week."
  },
  {
    name: "Emily Davis",
    role: "Marketing Specialist at Creative Solutions",
    image: "https://randomuser.me/api/portraits/women/65.jpg",
    text: "The seamless integration with our existing systems made the transition to DocumentAI effortless. The support team was always available to help."
  },
  {
    name: "Michael Brown",
    role: "Software Developer at CodeWorks",
    image: "https://randomuser.me/api/portraits/men/33.jpg",
    text: "The accuracy of data extraction from invoices has been remarkable. DocumentAI has reduced errors and improved our overall workflow efficiency."
  },
  {
    name: "Sarah Wilson",
    role: "Project Manager at InnovateX",
    image: "https://randomuser.me/api/portraits/women/68.jpg",
    text: "DocumentAI data visualization tools have provided us with clear insights and helped us make informed decisions quickly and effectively."
  },
  {
    name: "David Lee",
    role: "Operations Manager at LogisticsPro",
    image: "https://randomuser.me/api/portraits/men/78.jpg",
    text: "The reliability and uptime of DocumentAI services have been exceptional. We can trust that our invoicing operations will run smoothly at all times."
  },
  {
    name: "Laura White",
    role: "Financial Analyst at MoneyMatters",
    image: "https://randomuser.me/api/portraits/women/72.jpg",
    text: "Using DocumentAI has been a great decision for our financial team. The automated Document Extraction has streamlined our processes and improved accuracy."
  },
];

const columnSpans = [
  'col-span-2',
  'col-span-3',
  'col-span-3',
  'col-span-2',
  'col-span-2',
  'col-span-3'
];

const TestimonialsSection = () => {
  return (
    <section className="max-w-5xl mx-auto w-full px-10 dark:bg-gray-800 dark:text-white">
      <div className="flex items-center justify-center flex-col gap-y-2 py-12 md:pt-24 md:pb-12">
        <h2 className="text-2xl md:text-3xl lg:text-4xl text-gray-700 font-bold">
          Discover how our service can benefit you
        </h2>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-5 gap-5 w-full">
        {testimonials.map((testimonial, index) => (
          <div
            key={index}
            className={`border p-7 rounded-xl bg-white dark:bg-gray-700 drop-shadow-md border-neutral-200/50 ${columnSpans[index]} flex flex-col gap-y-10 justify-between`}
          >
            <div className="flex flex-col gap-y-3.5">
              <p className="font-semibold text-gray-700 text-lg">{testimonial.name.split(' ')[0]} speaks</p>
              <p className="text-slate-700/90 dark:text-gray-300">
                {testimonial.text}
              </p>
            </div>
            <div className="flex flex-col">
              <img
                src={testimonial.image}
                alt={testimonial.name}
                className="h-10 w-10 rounded-full"
              />
              <p className="pt-2 text-sm font-semibold">{testimonial.name}</p>
              <p className="text-sm font-medium text-slate-700/70 dark:text-gray-400">
                {testimonial.role}
              </p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default TestimonialsSection;
