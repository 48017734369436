import React, { useEffect, useState } from 'react';
import { FaUserEdit, FaEnvelope, FaPhone, FaMapMarkerAlt, FaSave, FaTimes, FaUser } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Button from '../../Components/Button';
import Container from '../../Components/Container';
import ContentHeader from '../../Components/ContentHeader';
import Card from '../../Components/Card'; 
import { updateProfile, userProfile } from '../../redux/actions/authActions';

const Profile = () => {
    const [isEditing, setIsEditing] = useState(false);
    const user = JSON.parse(localStorage.getItem('user'))
    const [profile, setProfile] = useState({
        name: 'No Name',
        email: user.email,
        role: 'Admin',
        address: 'No Address'
    });
    const [errors, setErrors] = useState({
        name: '',
        email: '',
        role: '',
        address: '',
    });
    
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchData = async () => {
            try {
              const data = await dispatch(userProfile());
              if(data) {
                setProfile({
                    name: data.name || "",
                    email: data.email || "",
                    role: 'Admin',
                    address: data.address || ""
                });
                }
            } catch (error) {
              console.log(error.message);
            } 
          };
      
          fetchData();
    }, [])

    const handleChange = (e) => {
        const { name, value } = e.target;
    
        let errorMessage = '';
        if (name === 'address') {
            if (value.length > 500) {
                errorMessage = 'Address must be less than 500 characters.';
            }
        } else {
            if (value.length > 200) {
                errorMessage = `${name.charAt(0).toUpperCase() + name.slice(1)} must be less than 200 characters.`;
            }
        }
    
        setErrors((prevErrors) => ({ ...prevErrors, [name]: errorMessage }));
    
        if (!errorMessage) {
            setProfile((prevProfile) => ({ ...prevProfile, [name]: value }));
        }
    };
    

    const handleSubmit = (e) => {
        e.preventDefault();
    
        const hasErrors = Object.values(errors).some((error) => error !== '');
        if (hasErrors) {
            toast.error('Please fix the errors before submitting.');
            return;
        }
    
        try {
            dispatch(updateProfile(profile)).then((res) => {
                toast.success('Profile updated successfully');
            });
            setIsEditing(false);
        } catch (error) {
            console.error('Profile update error:', error);
        }
    };
    

    return (
        <Container>
            <ContentHeader heading="Settings" style="text-left py-8" subheading="Your great subtitle goes here" />
            <Card className="bg-gradient-to-r from-primary-50 to-purple-50 shadow-lg rounded-xl py-12 px-6">
                {isEditing ? (
                    <form onSubmit={handleSubmit} className="space-y-6">
                        <div className="md:mx-20 mt-4 grid grid-cols-1 gap-4 md:grid-cols-2">
                            <div className="mt-4 md:col-span-1">
                                <label className="block text-gray-700 font-semibold mb-1" htmlFor="name">
                                    Name
                                </label>
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    value={profile.name}
                                    onChange={handleChange}
                                    className={`w-full px-4 py-4 border ${errors.name ? 'border-red-500' : 'border-gray-300'} rounded-md text-gray-900 dark:text-gray-200 dark:bg-gray-700 focus:outline-none focus:ring-2 ${errors.name ? 'focus:ring-red-500' : 'focus:ring-primary-400'}`}
                                    required
                                />
                                {errors.name && <p className="text-red-500 text-xs mt-2">{errors.name}</p>}
                            </div>
                            <div className="mt-4 md:col-span-1">
                                <label className="block text-gray-700 font-semibold mb-1" htmlFor="email">
                                    Email
                                </label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={profile.email}
                                    onChange={handleChange}
                                    className={`w-full px-4 py-4 border ${errors.email ? 'border-red-500' : 'border-gray-300'} rounded-md text-gray-900 dark:text-gray-200 dark:bg-gray-700 focus:outline-none focus:ring-2 ${errors.email ? 'focus:ring-red-500' : 'focus:ring-primary-400'}`}
                                    disabled
                                />
                                {errors.email && <p className="text-red-500 text-xs mt-2">{errors.email}</p>}
                                </div>
                            <div className="mt-4 md:col-span-1">
                                <label className="block text-gray-700 font-semibold mb-1" htmlFor="role">
                                    Role
                                </label>
                                <input
                                    type="text"
                                    id="role"
                                    name="role"
                                    value={profile.role}
                                    onChange={handleChange}
                                    className={`w-full px-4 py-4 border ${errors.role ? 'border-red-500' : 'border-gray-300'} rounded-md text-gray-900 dark:text-gray-200 dark:bg-gray-700 focus:outline-none focus:ring-2 ${errors.role ? 'focus:ring-red-500' : 'focus:ring-primary-400'}`}
                                    disabled
                                />
                                {errors.role && <p className="text-red-500 text-xs mt-2">{errors.role}</p>}
                                </div> 
                            <div className="mt-4 md:col-span-2">
                                <label className="block text-gray-700 font-semibold mb-1" htmlFor="address">
                                    Address
                                </label>
                                <textarea
                                    id="address"
                                    name="address"
                                    value={profile.address}
                                    onChange={handleChange}
                                    className={`w-full px-4 py-2 border ${errors.address ? 'border-red-500' : 'border-gray-300'} rounded-md text-gray-900 dark:text-gray-200 dark:bg-gray-700 focus:outline-none focus:ring-2 ${errors.address ? 'focus:ring-red-500' : 'focus:ring-primary-400'}`}
                                    rows="3"
                                    required
                                ></textarea>
                                {errors.address && <p className="text-red-500 text-xs mt-2">{errors.address}</p>}
                                </div>
                        </div>
                        <div className="flex justify-center space-x-4">
                            <Button
                                type="submit"
                                className="flex items-center md:px-6 md:py-3 text-white bg-gradient-to-r from-primary-400 to-primary-600 hover:from-primary-600 hover:to-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-400 rounded-full shadow-lg"
                            >
                                <FaSave className="mr-2" />
                                Save Changes
                            </Button>
                            <Button
                                type="button"
                                className="flex items-center border border-red-700 md:px-6 md:py-3 text-red-700 bg-white shadow-md hover:bg-red-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-red-400 rounded-full"
                                onClick={() => setIsEditing(false)}
                            >
                                <FaTimes className="mr-2" />
                                Cancel
                            </Button>
                        </div>
                    </form>
                ) : (
                    <div className="space-y-6">
                        <div className="flex justify-center">
                            <FaUser className="text-primary-400 p-2 text-6xl mb-4  border-4 border-primary-400 rounded-full" />
                        </div>
                        <div className="md:mx-20 mt-4 grid grid-cols-1 gap-4 md:grid-cols-2">
                            <div className="flex items-center space-x-4 bg-white rounded-lg p-4 shadow-md">
                                <FaUserEdit className="text-xl text-purple-600" />
                                <div>
                                    <h3 className="text-lg font-semibold text-gray-700">Name</h3>
                                    <p className="text-gray-900">{profile.name}</p>
                                </div>
                            </div>
                            <div className="flex items-center space-x-4 bg-white rounded-lg p-4 shadow-md">
                                <FaEnvelope className="text-xl text-purple-600" />
                                <div>
                                    <h3 className="text-lg font-semibold text-gray-700">Email</h3>
                                    <p className="text-gray-900">{profile.email}</p>
                                </div>
                            </div>
                            <div className="flex items-center space-x-4 bg-white rounded-lg p-4 shadow-md">
                                <FaUserEdit className="text-xl text-purple-600" />
                                <div>
                                    <h3 className="text-lg font-semibold text-gray-700">Role</h3>
                                    <p className="text-gray-900">{profile.role}</p>
                                </div>
                            </div> 
                            <div className="flex items-center space-x-4 bg-white rounded-lg p-4 shadow-md">
                                <FaMapMarkerAlt className="text-xl text-purple-600" />
                                <div>
                                    <h3 className="text-lg font-semibold text-gray-700">Address</h3>
                                    <p className="text-gray-900">{profile.address}</p>
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-center">
                            <Button
                                type="button"
                                className="flex items-center mt-8 px-6 py-3 text-white bg-gradient-to-r from-purple-500 to-purple-600 hover:from-purple-600 hover:to-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-400 rounded-full shadow-lg"
                                onClick={() => setIsEditing(true)}
                            >
                                <FaUserEdit className="mr-2" />
                                Edit Profile
                            </Button>
                        </div>
                    </div>
                )}
            </Card>
        </Container>
    );
};

export default Profile;
